export class FileModel {
    id:number;
    buyer_id: number;
    billing_id: number;
    url: string;
    name: string;
    status: number;
    created_at: string;
   
    constructor() {
        this.id             = 0;
        this.buyer_id       = 0;
        this.billing_id     = 0;
        this.url            = '';
        this.name           = '';
        this.status         = 1;
        this.created_at     = '';
    }
}
