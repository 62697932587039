import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { NgForm } from '@angular/forms';
import { ConexionesService } from 'src/app/services/conexiones.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  usuario: UsuarioModel;
  plan = 'Buscando...';


  constructor( private conex: ConexionesService) {

    this.usuario = JSON.parse(localStorage.getItem('kobraUser'));

    // Evaluo el plan que tiene
    if (this.usuario.planID !== 2) {
      this.plan = 'Free';
    } else {
      this.plan = 'Premium';
    }

  }

  ngOnInit() {
  }


  guardar(form: NgForm) {
    if ( form.invalid) { return; }

    console.log('usuario', this.usuario);
    this.conex.guardarDato('/sellers/update', this.usuario)
              .subscribe( resp => {
                  console.log( resp );
                  localStorage.setItem('kobraUser', JSON.stringify(this.usuario));
                  this.exito();
                });
  }

  
  modificarRut(rut, origen) {
    console.log('salio del input');
    let div1;
    let div2;
    let div3;
    let div4;

    if ( rut.length === 9 ) {
      div1 = rut.slice(0, 2);
      div2 = rut.slice(2, 5);
      div3 = rut.slice(5, 8);
      div4 = rut.slice(8, 9);
      rut = div1 + '.' + div2 + '.' + div3 + '-' + div4;

      if (origen === 'personal') {
        this.usuario.rut = rut;
      } else {
        this.usuario.account_rut = rut;
      }
    }
    if ( rut.length === 8 ) {
      div1 = rut.slice(0, 1);
      div2 = rut.slice(1, 4);
      div3 = rut.slice(4, 7);
      div4 = rut.slice(7, 8);
      rut = div1 + '.' + div2 + '.' + div3 + '-' + div4;

      if (origen === 'personal') {
        this.usuario.rut = rut;
      } else {
        this.usuario.account_rut = rut;
      }
    }

  }// FIN MODFICAR RUT



  /////////// Warnings

exito() {
  Swal.fire({
    title: 'Listo',
    text: 'Datos guardados con exito',
    icon: 'success'
  });
}
}
