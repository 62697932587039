<div class="row fondo animated ">

    <div class="col-10 offset-1 col-md-4 offset-md-4 col-lg-2 offset-lg-5 text-center">
        <img src="/assets/images/k_logo_blanco.svg" width="200px">

        <form (ngSubmit)="login(f)" #f="ngForm" class="animated fadeInLeft">

            <div class="campo">
                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animated fadeIn">Debes ingresar un correo</span>
                <input class="form-control" type="email" name="email" [(ngModel)]="email" required minlength="3" email placeholder="Email">
            </div>

            <div class="">
                <button class="btn btn-outline-success btn-block mt-3" type="submit">
                Resetear password
                </button>
            </div>
            <hr>
            <div routerLink="/login" class="pointer">
                <p class="text-light">
                    <i class="fas fa-hand-point-left"></i> Volver
                </p>
            </div>

        </form>





    </div>
</div>