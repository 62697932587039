import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentModel } from 'src/app/models/payment.model';
import { ConexionesService } from 'src/app/services/conexiones.service';

@Component({
  selector: 'app-ok',
  templateUrl: './ok.component.html',
  styleUrls: ['./ok.component.css']
})



export class OkComponent {

  id:any               = '';
  loading              = true;
  payment:PaymentModel = new PaymentModel();
  pagado               = '';
  orders               = [];

  constructor( private route: ActivatedRoute,
               private conex: ConexionesService){
                this.id = this.route.snapshot.paramMap.get('id');
                this.getPayments();
               }


getPayments(){
  console.log('id', this.id);
  this.conex.traeDatos('/getPayments/' + this.id).subscribe(resp => {
     console.log('payment', resp['datos']);
     this.payment = resp['datos'][0];
     console.log('payment', this.payment);
 
     if (this.payment.status == 2){
      this.getBill();
      this.getOrders();

     } else {
      this.loading = false;
     }
  
    })
  }
  
  
  getBill(){
    this.conex.traeDatos('/getBill/' + this.payment.billing_id).subscribe( resp =>{
      console.log('bill', resp['datos'][0]);
      const bill = resp['datos'][0]

      if (bill.status < 2 && bill.payment_id == 0){
         bill.status     = 2;
         bill.pay_at     = this.payment.reg_date;
         bill.payment_id = this.payment.id;
         this.updateBill(bill);
      } else{
        console.log('Ya estaba pagado')
        this.conex.sonido('cascabel.mp3');
        this.loading = false;
      }
    })
}


updateBill(bill){
  this.conex.guardarDato('/billings/update', bill).subscribe( resp => {
    console.log('actualize', bill, resp);
    this.conex.sonido('cascabel.mp3');
    this.loading = false;

  })
}


getOrders(){
  this.conex.traeDatos('/ordersDetalle/' + this.payment.billing_id).subscribe( resp => {
    console.log('orders', resp);
    this.orders = resp['datos'];
  })

}

descargarInvoice(){
  console.log('descargar invoice');
  window.print();
}

}

// VD = Venta Débito.
// VN = Venta Normal.
// VC = Venta en cuotas.
// SI = 3 cuotas sin interés.
// S2 = 2 cuotas sin interés.
// NC = N Cuotas sin interés
// VP = Venta Prepago.