import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ConexionesService } from 'src/app/services/conexiones.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

usuario: UsuarioModel;
recordarme = false;

  constructor( private auth: AuthService,
               private conex: ConexionesService,
               private router: Router) { }

  ngOnInit() {
    this.usuario = new UsuarioModel();
  }

  onSubmit(form: NgForm) {
    if (form.invalid) { return; }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando datos en la matrix'
    });
    Swal.showLoading();

    this.auth.nuevoUsuario(this.usuario)
              .subscribe(resp => {

                if (this.recordarme) {
                  localStorage.setItem('email', this.usuario.email);
                }

                this.crearUsuarioBD();

              }, (err) => {
                Swal.close();
                if (err.error.error.message === 'EMAIL_EXISTS') {
                  this.error('Ese correo ya está siendo utilizado');
                } else {
                  this.error(err.error.error.message);
                }
              });
  }

  crearUsuarioBD() {
    this.conex.guardarDato('/sellers/insert', this.usuario)
              .subscribe(resp => {
                                  Swal.close();

                                  Swal.fire({
                                    title: this.usuario.firstname,
                                    text: 'Datos guardados con éxito',
                                    icon: 'success'
                                  });

                                  localStorage.setItem('kobraUser', JSON.stringify(this.usuario));
                                  this.router.navigateByUrl('/dashboard');
                                 }, (err) => {
                                  this.error('Lo sentimos ocurrió un error intentalo de nuevo más tarde');
                                 });
  }






  // warnings

  error(mensaje) {
    Swal.fire({
      icon: 'error',
      title: 'Cuuuuueeeeeeec',
      text: mensaje,
    });
  }
}
