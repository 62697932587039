
export class BuyerModel {
     id: string;
     seller_id: number;
     first_name: string;
     last_name: string;
     phone: string;
     email: string;
     detail: string;
     photo: string;
     status: number;

     constructor() {
          this.status = 1;
          this.id = '';
          this.phone = '';
          this.detail = '';
          this.photo = '';
     }




}
