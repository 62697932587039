
export class ProductModel {
     id: string;
     seller_id: number;
     name: string;
     detail: string;
     price: number;
     category: string;
     status: number;

     constructor() {
          this.id = '';
          this.detail = '';
          this.category = 'Producto';
          this.status = 1;
     }
}
