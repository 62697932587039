import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UsuarioModel } from '../models/usuario.model';


@Injectable({
  providedIn: 'root'
})
export class ConexionesService {


  private url = 'https://kobra-377821.rj.r.appspot.com';
  // public url = 'http://localhost:8080';
  public port = 8080;

  hoy         = new Date();
  usuario     = new UsuarioModel();


  constructor(private http: HttpClient) {

      if (localStorage.getItem('kobraUser')) {
         this.usuario = JSON.parse(localStorage.getItem('kobraUser'));
      }
  }

  traeDatos( ruta ) {
    return this.http.get( this.url + ruta );
 }


  guardarDato(ruta, body) {
    return this.http.post( this.url + ruta , body );
  }



  traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;
    this.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                return this.usuario;
              }, (err) => {
                console.log(err);
                return 'error';
              });
    }

    sendMessage(body) {
      return this.http.post( this.url + '/formulario', body );
      }

    sonido(sonido) {
      const audio = new Audio();
      audio.src = `./assets/sounds/${sonido}`;
      audio.play();
    }


    enviarCorreo(bill, orders) {
      const body = {
                    from            : this.usuario.email,
                    to              : bill.email,
                    seller          : this.usuario.firstname + ' ' + this.usuario.lastname,
                    buyer           : bill.first_name + ' ' + bill.last_name,
                    asunto          : `Kobra: ` + this.usuario.firstname + ' ' + this.usuario.lastname,
                    mensaje         : 'Mensaje de prueba desde Kobra',
                    canasta         : orders,
                    filename        : '',
                    path            : '',
                    account_name    : this.usuario.account_name,
                    account_number  : this.usuario.account_number,
                    account_type    : this.usuario.account_type,
                    account_rut     : this.usuario.account_rut,
                    bank            : this.usuario.bank,
                    billing_id      : bill.id,
                    fecha           : this.hoy.toLocaleDateString(),
                    payLink         : `http://www.kobra.cl/#/invoice/${bill.id}`
                    // filename        : '',
                    // path            : '',
                  };

      console.log('bill', bill);
      console.log('body', body);

      return this.http.post( this.url + '/formulario', body )

      // this.sendMessage(body).subscribe(() => {
      //   console.log('mensaje enviado con exito');
      // });

    }
}
