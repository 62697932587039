import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ConexionesService } from 'src/app/services/conexiones.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: UsuarioModel;
  recordarme = true;

  constructor(private auth: AuthService,
              private router: Router,
              private conex: ConexionesService) { }

  ngOnInit() {
    this.usuario = new UsuarioModel();

    if (localStorage.getItem('email')) {
      this.usuario.email = localStorage.getItem('email');
    }
  }

  login(form: NgForm) {
    if (form.invalid) { return; }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Verificando datos'
    });
    Swal.showLoading();

    this.auth.login(this.usuario)
              .subscribe(resp => {

                if (this.recordarme) {
                  localStorage.setItem('email', this.usuario.email);
                }
                this.traerUsuario();
              }, (err) => {
                Swal.close();
                this.error();
              });
}

traerUsuario() {
  this.conex.traeDatos(`/seller/${this.usuario.email}`)
            .subscribe( resp => {
              localStorage.setItem('kobraUser', JSON.stringify(resp['datos'][0]));
              this.router.navigateByUrl('/dashboard');
              Swal.close();
            }, (err) => {
              Swal.close();
              console.log(err);
              this.errorBD();
            });
}



// Warnings



error() {
  Swal.fire({
    title: 'Intentalo de nuevo',
    text: 'Error en el mail o la contraseña',
    icon: 'warning'
  });
}

errorBD(){
  Swal.fire({
    title: 'Error de conexión',
    text: 'No se pudo conectar a la Base datos',
    icon: 'warning'
  });
}

}
