import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConexionesService } from 'src/app/services/conexiones.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { BuyerModel } from 'src/app/models/buyer.model';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { PaymentModel } from 'src/app/models/payment.model';
import { FechasService } from 'src/app/services/fechas.service';
import { BillingModel } from 'src/app/models/billing.model';

 
@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {

  loading  = true;
  loading2 = false;
  buyerId;
  usuario: UsuarioModel;
  buyer: BuyerModel       = new BuyerModel();
  orders: any[]           = [];
  billingsAll: any[]      = [];
  billings: any[]         = [];

  
 
  modalKobrar = false;
  bill: any;
  canasta: any[]          = [];


  date              = new Date();
  firstDay:any      = new Date(new Date().getFullYear(), 0, 1);
  lastDay:any       = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  billing:any       = new BillingModel();
  total             = 0;
  payment:any       = '';



  constructor( private router: Router,
               private route: ActivatedRoute,
               private conex: ConexionesService,
               private _fs: FechasService) {

               this.buyerId = this.route.snapshot.paramMap.get('id');
               this.traerUsuario();
               this.getBuyer();

               this.firstDay = this._fs.modificarFecha(this.firstDay);
               this.lastDay  = this._fs.modificarFecha(this.lastDay);


               console.log('dia 1', this.firstDay)
               console.log('last', this.lastDay)
              }


  ngOnInit() {
  }


  guardar(form: NgForm) {
    if (form.invalid) { return; }

    this.conex.guardarDato(`/buyers/update`, this.buyer)
              .subscribe(resp => {
                this.exito();
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }



  traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;

    this.conex.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                console.log('this,usuario', this.usuario);
                this.traerBillings();

              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }
  
  getBuyer() {
    this.conex.traeDatos(`/buyer/${this.buyerId}`)
              .subscribe( resp => {
                this.buyer  = resp['datos'][0];
                console.log('this.buyer', this.buyer);
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }

  traerBillings() {
    console.log('aca');
    this.loading = true;
    this.conex.traeDatos(`/billingsXbuyer/${this.buyerId}/${this.firstDay}/${this.lastDay}`)
              .subscribe( resp => {
                console.log('billings', resp['datos']);
                this.billingsAll = resp['datos'];
                this.billings = this.billingsAll;
              }, (err) => {
                this.loading = false;
                this.error('Error', err.errors);
              });
            }


    verKobro(k){
      console.log('biiling', k);
      this.loading2 = true;
      this.billing  = k
      this.orders   = [];
      this.getOrders();

      if(k.bill_status > 1){
        this.getPayments();
      }
    }        


    filtrar(value){
      console.log('value', value)
      if (value == 9){
        this.billings = this.billingsAll;
      } else {
        this.billings = this.billingsAll.filter( b => b.bill_status == value);
      }
    }

// Status de Billings
// 0 = borrado
// 1 = grabado/impago
// 2 = pagado

// Status de Orders 
// 0 = Borrado
// 1 = grabado / Kanasta
// 2 = kobrado / impago
// 3 = pagado

    getOrders(){
      this.total = 0;
      this.conex.traeDatos(`/ordersDetalle/${this.billing.id}`)
                .subscribe(resp => {
                    console.log('orders', resp['datos']);
                    this.orders = resp['datos'];
                    for ( let o of this.orders){
                      this.total += o.quantity * o.price;
                    }
                    this.loading2 = false;
      })
    }

    getPayments(){
      this.conex.traeDatos(`/payments/${this.billing.id}`)
      .subscribe( resp => {
              console.log('payments', resp);
              if (resp['datos'].length > 1){
                this.payment = resp['datos'].find( (p:any) => p.status == 2);
              } else {
                this.payment = resp['datos'][0];
                console.log('payment', this.payment);
              }            })
    }

    borrar() {
      this.buyer.status = 0;
      this.conex.guardarDato(`/buyers/update`, this.buyer)
                .subscribe( resp => {
                    this.exitoBorrar();
                    this.router.navigateByUrl('/clientes');
                  }, (err) => {
                    this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
                  });
    }
  
    vender() {
      this.router.navigateByUrl(`/venta/${this.buyer.id}`);
    }
  

  kobrar() {
    this.conex.enviarCorreo(this.billing, this.orders).
        subscribe( resp => {
          this.conex.sonido('cascabel.mp3');
          Swal.fire({
            title: 'Kobransa Enviada',
            icon: 'success'
          });
        });
  }

// =========================================================================================== //
// =========================================================================================== //
// ============================================= GUARDAR UN PAGO ============================= //
// =========================================================================================== //
// =========================================================================================== //

  guardarPayment(formaPago) {
    this.loading = true;
    const bill          = this.billing;
    const payment       = new PaymentModel();
    payment.status      = 2;
    payment.seller_id   = Number(this.usuario.id);
    payment.billing_id  = bill.id;
    payment.amount      = bill.amount;
    payment.origen      = formaPago;

    this.conex.guardarDato(`/payments/insert`, payment)
              .subscribe( resp => {
                bill.payment_id = resp['datos'].insertId;
                this.pagarBill(bill);
              });
  }

  pagarBill(bill) {
    bill.pay_at = new Date();
    bill.status = 2;
    this.conex.guardarDato('/billings/update', bill)
              .subscribe( resp => {
                this.conex.sonido('cascabel.mp3');
                this.actualizarOrders(3);
              });

  }

  actualizarOrders(status) {
    const totalOrders = this.orders.length;
    let conteo = 0;
    for ( const order of this.orders ) {
      order.status = status;
      this.conex.guardarDato('/updateOrder', order)
                .subscribe( resp => {
                            console.log('actualizé', order);
                            conteo ++;

                            if (conteo >= totalOrders){
                              this.loading = false;
                              location.reload();
                            }
                            }, (err) => {
                              console.log(err.error);
                              this.error('Error al guardar la canasta', err.error);
                            });
    }
  }



  //////////////// ==================== Warnings ============================== ////////////////////
  //////////////// ==================== Warnings ============================== ////////////////////
  //////////////// ==================== Warnings ============================== ////////////////////
  //////////////// ==================== Warnings ============================== ////////////////////
  //////////////// ==================== Warnings ============================== ////////////////////

  error(titulo, mensaje) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }

  exito() {
    Swal.fire({
      title: 'Listo',
      text: 'Datos guardados con exito',
      icon: 'success'
    });
  }
  exitoBorrar() {
    Swal.fire({
      title: 'Listo',
      text: 'Usuario Eliminado',
      icon: 'success'
    });
}

alertaBorrar() {
  Swal.fire({
    title: '¿Estás seguro?',
    text: `Quieres borrar ${this.buyer.first_name}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Borrar',
    cancelButtonText: 'No'
  }).then((result) => {
    if (result.value) {
      this.borrar();
    } else {
      return;
    }
  });
}


async alertaPago() {
  this.conex.sonido('Klick.mp3');
  Swal.fire({
    title: 'Forma de pago',
    text: '¿A través de que forma de pago fue cancelado este Kobro?',
    input: 'select',
    inputOptions: {
          Efectivo: 'Efectivo',
          Transferencia: 'Transferencia',
          Cheque: 'Cheque',
        },
        inputPlaceholder: 'Selecciona un medio de pago',
    showCancelButton: true,

    inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== '' ) {
            this.guardarPayment(value);
          } else {
            resolve('Tienes que escoger un medio de pago :)');
          }
          if (value) {
            Swal.fire(`Kobro guardado`);
            this.modalKobrar = false;
          }
        });
      }
    });
}




}
