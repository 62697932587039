import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentModel } from 'src/app/models/payment.model';
import { ConexionesService } from 'src/app/services/conexiones.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent {

  id:any            = '';
  loading           = true;
  loading2          = false;
  invoice:any       = {};
  orders:any[]      = [];
  pago:PaymentModel = new PaymentModel();



  constructor( private router: Router,
              private route: ActivatedRoute,
              private conex: ConexionesService
              ){
                this.id = this.route.snapshot.paramMap.get('id');
                this.getBilling();
              }

ngOnInit() {}

getBilling(){
                this.conex.traeDatos('/invoice/' + this.id).subscribe( resp => {
                  console.log('invoice', resp);
                  this.invoice = resp['datos'][0];
                 this.getOrders();
                })
}

getOrders(){
                this.conex.traeDatos('/ordersDetalle/' + this.id).subscribe( resp => {
                  console.log('orders', resp);
                  this.orders = resp['datos'];
                  this.loading = false;
                })
}

crearPayment(){
  this.loading2 = true;
  
  this.pago = new PaymentModel();
  this.pago.seller_id  = this.invoice.seller_id;
  this.pago.billing_id = this.id;
  this.pago.amount     = this.invoice.amount;
  this.pago.origen     = 'webpay';
  this.pago.status     = 1;

  console.log('payment', this.pago);

  this.conex.guardarDato('/payments/insert', this.pago)
            .subscribe( resp => {
                console.log('cree un pago', resp);
                this.pago.id = resp['datos'].insertId;
                console.log('pago', this.pago);
                this.pagar();
              })
}

pagar(){
  const body = {
            amount: this.pago.amount,
            buyOrder: this.pago.id.toString(),
            sessionId: 'S' + this.pago.id,
            commerceCode: this.invoice.commerceCode,
            quotes: 1
    };

    let response = {
            token: '',
            url: '',
            orderId: '',
        };

   this.conex.guardarDato(`/wp/pagoKobra`, body)
        .subscribe( resp => {
          console.log('pagoKobra', resp);

          response.token    = resp['token'];
          response.url      = resp['url'];
          response.orderId  = 'orderId';
          console.log('response', response);

          // if(response.token === 'errorMontos'){
          //   this.router.navigateByUrl(`/error/0`);
          //   return;
          // }
          // if(response.token === 'yaPagado'){
          //   this.router.navigateByUrl(`/ok/0`);
          //   return;
          // }

          this.updateToken(response);
        }, err => {
          console.log('error webpay', err);
        })

}

updateToken(response){
  const body:any = {
    token: response.token,
    id: this.pago.id
  }
  this.conex.guardarDato('/updateToken', body).subscribe( resp => {
      this.loading2 = false;
      this.postear(response);
  })
}


postear(resp){
  const mapForm = document.createElement('form');
  mapForm.method = 'POST';
  mapForm.action = `${resp.url}`;
  mapForm.style.display = 'none';
  
  const mapInput = document.createElement('input');
  mapInput.type = 'hidden';
  mapInput.name = 'token_ws';
  mapInput.value = resp.token;
  mapForm.appendChild(mapInput);
  
  const mapInput1 = document.createElement('input');
  mapInput1.type = 'hidden';
  mapInput1.name = 'orderId';
  mapInput1.value = resp.orderId;
  mapForm.appendChild(mapInput1);
  
  const mapInput2 = document.createElement('input');
  mapInput1.type = 'hidden';
  mapInput1.name = 'storeId';
  mapInput1.value = '1'; // HACER DINAMICO
  mapForm.appendChild(mapInput2);
  
  
  
  document.body.appendChild(mapForm);
  
  mapForm.submit();
  }


}

