import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProductModel } from 'src/app/models/producto.model';
import { ConexionesService } from 'src/app/services/conexiones.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  mantenedor = false;
  product: ProductModel;
  usuario: UsuarioModel;
  existe = false;

  productos: any[] = [];

  constructor(private conex: ConexionesService) {

              this.product = new ProductModel();
              this.traerUsuario();
  }

  ngOnInit() {
  }

  nuevoProducto(){
    this.product = new ProductModel();
    this.existe = false;

  }


  guardar(form: NgForm) {
    if ( form.invalid) { return; }
    let tarea = 'insert';
    this.product.seller_id = Number(this.usuario.id);

    console.log('producto', this.product);

    // EVALUO SI EL PRODUCTO EXISTE PARA VER SI LO EDITO O LO BORRO;
    if (this.product.id) {
      console.log('existe');
      tarea = 'update';
    }

    this.conex.guardarDato(`/products/${tarea}`, this.product)
              .subscribe( resp => {
                  this.mantenedor = false;
                  this.traerProductos();
                  this.exito();
                }, (err) => {
                  this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
                });
  }



  editar(producto) {
    this.product = producto;
    console.log('este', this.product);
    this.existe = true;
  }


  borrar(producto) {
    this.product = producto;
    this.product.status = 0;

    console.log('borrar', this.product);

    this.conex.guardarDato(`/products/update`, this.product)
              .subscribe( resp => {
                  this.mantenedor = false;
                  this.traerProductos();
                  this.exito();
                }, (err) => {
                  this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
                });
  }

  


traerProductos() {
  this.conex.traeDatos(`/tabla/PRODUCTS/${this.usuario.id}`)
            .subscribe( resp => {
              this.productos = resp['datos'];
              console.log('productos', this.productos);
            }, (err) => {
              this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
            });
}


traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;

    this.conex.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                this.traerProductos();
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }


  //////////////// Warnings

  error(titulo, mensaje) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }

  exito() {
    Swal.fire({
      title: 'Listo',
      text: 'Datos guardados con exito',
      icon: 'success'
    });
}


alertaBorrar(p) {
  console.log(p);
  Swal.fire({
    title: '¿Estás seguro?',
    text: `Quieres borrar ${p.name}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Borrar',
    cancelButtonText: 'No'
  }).then((result) => {
    if (result.value) {
      this.borrar(p);
    } else {
      return;
    }
  });
}
}
