<div class="row fondo animated fadeIn">

    <div class="col-10 offset-1 col-lg-8 offset-lg-2">

        <form (ngSubmit)="guardar( f )" #f="ngForm" class="row formulario ">
            <div class="col-12 col-md-6 izq animated slideInLeft">
                <h1>Datos Usuario</h1>
                <p>Plan: {{plan}}</p>
                <div class="campo">
                    <span>Email</span>
                    <input class="form-control " type="email" name="email" [(ngModel)]="usuario.email" disabled>
                </div>

                <div class="campo">
                    <span *ngIf="f.submitted && f.controls['firstname'].errors" class="text-danger">El nombre es obligatorio</span>
                    <span>Nombre</span>
                    <input class="form-control " type="text" name="firstname" required minlength="2" [(ngModel)]="usuario.firstname">
                </div>

                <div class="campo">
                    <span *ngIf="f.submitted && f.controls['lastname'].errors" class="text-danger">El apellido es obligatorio</span>
                    <span>Apellido</span>
                    <input class="form-control " type="text" name="lastname" required minlength="2" [(ngModel)]="usuario.lastname">
                </div>
              
                <div class="campo">
                    <span>Marca</span>
                    <input class="form-control " type="text" name="brand" minlength="2" [(ngModel)]="usuario.brand">
                </div>

                <div class="campo">
                    <span>Rut</span>
                    <input class="form-control " type="text" name="rut" [(ngModel)]="usuario.rut" placeholder="99.999.999-9" (blur)="modificarRut(usuario.rut, 'personal')">
                </div>

                <div class="campo">
                    <span>Celular</span>
                    <input class="form-control " type="text" name="phone" [(ngModel)]="usuario.phone" placeholder="+569 12345678">
                </div>
                <div class="campo">
                    <span>Código de Comercio</span>
                    <input class="form-control " type="text" name="commerceCode" [(ngModel)]="usuario.commerceCode" placeholder="ej: 12345678">
                </div>
            </div>


            <!-- <div class="col-12 col-md-6 der animated slideInRight">
                <h1>Datos Bancarios</h1>
                <p>Campos son necesario para recibir pagos a través de Kobra</p>

                <div class="campo">
                    <span>Nombre Cuenta</span>
                    <input class="form-control " type="text" name="account_name" [(ngModel)]="usuario.account_name" placeholder="Nombre de la cuenta">
                </div>
                <div class="campo">
                    <span>Rut de la Cuenta</span>
                    <input class="form-control " type="text" name="account_rut" [(ngModel)]="usuario.account_rut" placeholder="Rut de la Cuenta" (blur)="modificarRut(usuario.account_rut, 'banco')">
                </div>

                <div class="campo">
                    <span>Banco</span>
                    <select class="form-control" name="bank" [(ngModel)]="usuario.bank">
                        <option>BANCO DE CHILE - EDWARDS</option>
                        <option>BANCO_BICE</option>
                        <option>BANCO_CONSORCIO</option>
                        <option>BANCO_DEL ESTADO DE CHILE</option>
                        <option>BANCO DO BRASIL S.A.</option>
                        <option>BANCO FALABELLA</option>
                        <option>BANCO INTERNACIONAL</option>
                        <option>BANCO PARIS</option>
                        <option>BANCO RIPLEY</option>
                        <option>BANCO SANTANDER</option>
                        <option>BANCO SECURITY</option>
                        <option>BBVA (BCO BILBAO VIZCAYA ARG)</option>
                        <option>BCI (BCO DE CREDITO E INV)</option>
                        <option>COOPEUCH</option>
                        <option>HSBC BANK</option>
                        <option>ITAU CHILE</option>
                        <option>ITAU-CORPBANCA</option>
                        <option>SCOTIABANK</option>
                    </select>
                </div>

                <div class="campo">
                    <span>Tipo Cuenta</span>
                    <select class="form-control" name="account_type" [(ngModel)]="usuario.account_type">
                        <option>Cuenta Corriente</option>
                        <option>Cuenta Vista</option>
                    </select>
                </div>

                <div class="campo">
                    <span>Número de Cuenta</span>
                    <input class="form-control " type="text" name="account_number" [(ngModel)]="usuario.account_number" placeholder="Número de la Cuenta">
                </div>
            </div> -->


            <div class="col-12 boton">
                <button class="btn btn-outline-success btn-block mt-3" type="submit">
                    Guardar
                </button>
            </div>

        </form>

    </div>
</div>




<!-- COLUMN DERECHA-->
<!-- <div class="col-md-6">
        <div class="campo">
            <input class="form-control " type="string" name="id" [(ngModel)]="usuario.id" disabled>
        </div>

        <div class="campo">
            <input class="form-control" type="text" name="banco" [(ngModel)]="usuario.banco" minlenth="11" placeholder="banco de chile">
        </div>
    </div> -->
<!-- FIN COLUMN DERECHA-->