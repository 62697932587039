<div class="row fondo animate__animated animate__fadeIn ">

    <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
        <img src="/assets/images/k_logo_blanco.svg" class="animate__animated animate__fadeInDown"width="200px">


        <form (ngSubmit)="login(f)" #f="ngForm" class="animated fadeInLeft">

            <div class="campo">
                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animated fadeIn">El correo es obligario</span>
                <input class="form-control" type="email" name="email" [(ngModel)]="usuario.email" required minlength="3" email placeholder="Email">
            </div>

            <div class="campo" data-validate="Password is required">
                <span *ngIf="f.submitted && f.controls['pass'].errors" class="text-danger animated fadeIn">El password es obligatorio</span>
                <input class="form-control" type="password" name="pass" [(ngModel)]="usuario.password" required minlength="6" placeholder="Password">
            </div>

            <div class="">
                <button class="btn btn-outline-success btn-block mt-3" type="submit">
                  Ingresar
                  </button>
            </div>

            <div class="campo mb-4">
                <div class="form-check text-start">
                    <input [(ngModel)]="recordarme" class="form-check-input" id="ckb1" type="checkbox" name="remember-me">
                    <label class="form-check-label text-light" for="defaultCheck1">
                        Recordarme
                    </label>
                </div>
            </div>
            <div>
                <a routerLink="/registro" class="txt1 text-light">
                  ¿No tienes cuenta?
                </a>
            </div>
            <div>
                <a routerLink="/resetpass" class="txt1 text-light">
                  Olvidé mi contraseña
                </a>
            </div>

        </form>





    </div>
</div>


<!-- <div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100 p-t-50 p-b-90">
            <form class="login100-form validate-form flex-sb flex-w">

                <span class="login100-form-title p-b-51">
                  Login
              </span>

<div class="wrap-input100 m-b-16">
    <input class="input100" type="text" name="username" placeholder="Username">

    <span class="focus-input100"></span>
</div>

<div class="wrap-input100 m-b-16" data-validate="Password is required">
    <input class="input100" type="password" name="pass" placeholder="Password">
    <span class="focus-input100"></span>
</div>

<div class="flex-sb-m w-full p-t-3 p-b-24">
    <div class="contact100-form-checkbox">
        <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
        <label class="label-checkbox100" for="ckb1">
                          Recordar mi usuario
                      </label>
    </div>

    <div>
        <a routerLink="/registro" class="txt1">
                          ¿No tienes cuenta?
                      </a>
    </div>
</div>

<div class="container-login100-form-btn m-t-17">
    <button class="login100-form-btn" type="submit">
                      Ingresar
                  </button>
</div>

</form>
</div>
</div>
</div> -->