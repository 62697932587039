
<div class="row celeste pad20 animated fadeIn header r0" *ngIf="!loading" >
    <div class="col-12 col-md-2 mt-3">
        <h1>Kobros <span *ngIf="!loading">({{billings.length | number}})</span></h1>
        <!-- <button class="btn btn-warning" routerLink="/invoice/88"> ir</button> -->
    </div>
</div>


<form (ngSubmit)="buscar( f )" #f="ngForm" class="row celeste pad20 r0" *ngIf="!loading" >
    
    <div class="col-12 col-md-3 offset-md-2">
        <div class="form-group">
            <label>Fecha Inicial</label>
            <input type="date" class="form-control" placeholder="Inicio Periodo" [(ngModel)]="firstDay" name="fechaIni" required>
        </div>
    </div>

    <div class="col-12 col-md-3">
        <div class="form-group">
            <label>Fecha Final</label>
            <input type="date" class="form-control" placeholder="Fin Periodo" [(ngModel)]="lastDay" name="fechaFin" required>
        </div>
    </div>

    <div class="col-12 col-md-2">
        <div class="form-group text-center mt-4 text-left">
            <button type="submit" class="btn btn-light w-100">
                  <i class="fa fa-search"></i>   
                </button>
        </div>
    </div>    
</form>


<app-loading *ngIf="loading"></app-loading>


<div *ngIf="!loading" class="row celeste r0 resultados" >

    <div class="col-12 col-md-8 offset-md-2  ">

        <div class="row cifras animate__animated animate__fadeIn">
            <div class="col-4 hvr-rotate" style="color:#DA6B8C">
                <p>Kobrando</p>
                <h5>$ {{cifras.porKobrarTotal | number}}</h5>
            </div>
            <div class="col-4 hvr-rotate" style="color:#67A18B">
                <p>Pagados</p>
                <h5>$ {{cifras.pagadosTotal | number}}</h5>
            </div>
            <div class="col-4 hvr-rotate" style="color:#3171E1">
                <p>Total</p>
                <h5>$ {{cifras.totaltotal | number}}</h5>
            </div>
        </div>

        <div class="row billings bg-light animate__animated animate__fadeInng">
            <div class="col-12">
            
    
            <div class="row buscador f_gris  animated fadeIn">
                <div class="col-2 col-md-1 text-right">
                    <i class="fas fa-search fa-2x mt-1 text-primary"></i>
                </div>
                <div class="col-10 col-md-7">
                    <input class="form-control" type="text" class="form-control " name="searchString " placeholder="Buscador... " [(ngModel)]="searchString " />
                </div>
                <div class="col-12 col-md-3 estado">
                    <div class="form-group">
                        <select class="form-control" name="estado" [(ngModel)]="estado" (change)="filtrarBillings($event.target.value)">
                                <option [value]="Todos">Filtra por estado</option>
                                <option [value]="1">Impago</option>
                                <option [value]="2">Pagado</option>
                                <option [value]="0">Todos</option>
                            </select>
                    </div>
                </div>
            </div>
    
            <div *ngIf="billings.length > 0" class="row  listado">
                <div class="col-12">
                    <div class="table-responsive animated fadeIn">
                        <table class="table table-striped table-hover">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Total </th>
                                    <th scope="col">Fecha Kobro </th>
                                    <th scope="col">id</th>
                                </tr>
                            </thead>
                            <tbody class="f_blanco">
                                <tr *ngFor="let bill of billings | filter : {'id': searchString, 'first_name' : searchString, 'last_name' : searchString, 'email' : searchString, 'phone' : searchString  } ; index as i" (click)="detalle(bill)" class="pointer" data-bs-toggle="offcanvas" data-bs-target="#modalDetalle" aria-controls="modalDetalle">
                                    <td>{{i + 1}}</td>
                                    <td [ngClass]="bill.status == 1 ? 'text-danger' : 'text-success' ">{{bill.first_name | titlecase}} {{bill.last_name | titlecase}}</td>
                                    <td>$ {{bill.amount | number}}</td>
                                    <td>{{bill.created_at | date: 'dd/MM/yyyy'}} </td>
                                    <td>{{bill.id}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
            <div *ngIf="billings.length < 1" class="row">
                <div class="col-12 text-center mt-5" style="color:#1C1C1C">
                    <i class="far fa-meh-rolling-eyes fa-3x animated jackInTheBox"></i>
                    <p>daaaaaa.. Periodo sin kobros...</p>
                </div>
            </div>
            </div>
        </div>

       


    </div>
</div>






<div *ngIf="guardando" class="row fondoModif animated fadeIn r0">
    <div class="col-12 text-center" style="padding-top: 40vh;">
        <img class="animated infinite pulse" src="/assets/images/dibKobra.svg" width="50px">
        <h1 style="color:white; font-size: 1.2em;">{{guarandoText}}</h1>
    </div>
</div>



<div  class="offcanvas offcanvas-end" tabindex="-1" id="modalDetalle" aria-labelledby="modalDetalleLabel" [ngClass]="billDetalle.status == 1 ? 'pendiente' : 'pagado'" #modalDetalle  data-bs-backdrop="static">

  <div class="offcanvas-body">

    <div  class="detalle">

        <div class="row headearDetalle">
            <div class="col-10">
                <h1 style="margin:0">Detalle Kobro</h1>
                <p>Total: $ {{billDetalle.amount | number}}</p>
                <p *ngIf="billDetalle.payment_id > 0">Pagado el: {{payment.reg_date| date: 'dd/MM/yyyy HH:mm:ss'}} </p>
                <p *ngIf="billDetalle.payment_id > 0">Medio de pago: {{payment.origen | titlecase}} </p>
            </div>
            <div class="col-2 text-right" >
                <button type="button" class="btn-close hvr-rotate" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
        </div>

        <div class="row tabla ">
            <div class="col-12 pad20">
                <div class="table-responsive tabla animated fadeIn ">
                    <table class="table ">
                        <thead class="thead-light" style="color:white">
                            <tr>
                                <th scope="col ">#</th>
                                <th scope="col ">Producto</th>
                                <th scope="col ">Cantidad </th>
                                <th scope="col ">Precio</th>
                                <th scope="col ">Total</th>
                                <th scope="col ">Fecha</th>
                            </tr>
                        </thead>
                        <tbody class="f_blanco text-light">
                            <tr *ngFor="let order of orders ; index as i">
                                <td>{{i + 1}}</td>
                                <td>{{order.product | titlecase}} </td>
                                <td>{{order.quantity | number}}</td>
                                <td>{{order.price | number}}</td>
                                <td>{{order.price * order.quantity | number}} </td>
                                <td>{{order.fechai | date: 'dd/MM/yyyy'}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> 

        <div *ngIf="billDetalle.status !=2" class="row headearDetalle">
            <div class="col-12 col-md-6 ">
                <button class="btn btn-outline-light w-100 animated zoomIn" (click)="kobrar(billDetalle)">
                <i class="fa-regular fa-envelope  fa-1x animated jackInTheBox"></i> Volver a Kobrar</button>
            </div>
            <div class="col-12 col-md-6 estado">
                <button class="btn btn-outline-light w-100 animated zoomIn" (click)="alertaPago(billDetalle)">
                    <i class="fas fa-dollar-sign fa-1x animated jackInTheBox"></i> Pagar</button>
            </div>
        </div>

        <!-- data-bs-dismiss="offcanvas" -->

        <hr *ngIf="billDetalle.status < 2">
        <div class="row">
            <div class="col-12 text-center">
                <p  data-bs-dismiss="offcanvas" (click)="verLink()" ><i class="fa-solid fa-file-invoice-dollar" ></i> Ver link de pago </p>
                <button *ngIf="billDetalle.status < 2"  data-bs-dismiss="offcanvas" class="btn btn-success" (click)="shareWhatsapp()"><i class="fa-brands fa-whatsapp"></i> Kobrar por Whatsapp !</button>
            </div>
            <div *ngIf="billDetalle.status < 2" class="col-12 text-center qr ">
                <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
                <a [href]="qrCodeDownloadLink" download="qrcode">Descargar qr</a>
            </div>
        </div>

  

    </div>



  </div>
</div> 