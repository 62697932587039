export class OrderModel {
     id: number;
     seller_id: number;
     buyer_id: number;
     product: string;
     product_id: number;
     billing_id: number;
     quantity: number;
     price: number;
     status: number;
     fechai: string;

     constructor() {
          this.id = 0;
          this.status = 1;
          this.quantity = 1;
          this.billing_id = 0;
          this.fechai = this.modificarFecha( new Date() );
     }

     modificarFecha(fecha) {
          const ano = (fecha.getFullYear()).toString();
          let mes = (fecha.getMonth() + 1).toString();
          let dia = (fecha.getDate()).toString();

          if ( mes.length < 2 ) {
            mes = '0' + mes;
          }

          if ( dia.length < 2 ) {
           dia = '0' + dia;
         }

          const fechaModif = ano + '-' + mes + '-' + dia;
          return fechaModif;
        }
}
