<div class="row contenedor celeste r0">
    <div class="col-12 col-lg-6 izq ">

    <div class="row titulo text-center pad20 animate__animated animate__fadeIn">
        <div class="col-12">
            <h1 >Productos <span>y servicios</span></h1>
            <p>Creo o edita los productos o servicios que vendes</p>
        </div>
    </div>

    

    <div class="row">
        <div class="col-10 offset-1 bg-light cajaProductos animate__animated animate__slideInUp">

            
            <div *ngIf="productos.length > 0" class="row pad20 ">
                    <div class="d-none d-md-block col-12 text-end">
                        <p class="nuevo pointer hvr-grow" data-bs-toggle="offcanvas" data-bs-target="#modalProducto" aria-controls="modalProducto" (click)="nuevoProducto()"><i class="fa-solid fa-plus" ></i> Crear nuevo producto</p>
                    </div>
                    <div class="col-2 col-lg-1 offset-1 text-center hvr-icon-rotate text-end">
                        <i class="fas fa-search mt-1 icono2 hvr-icon" style="font-size:1.5em"></i>
                    </div>
                    <div class="col-9">
                        <input class="form-control" type="text" class="form-control " name="searchString " placeholder="Buscador... " [(ngModel)]="searchString " />
                    </div>
            </div>
          
            <div class="row ">
                    <div *ngIf="productos.length > 0"class="col-12 tabla">
                        <table class="table table-hover">
                            <thead class="">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Precio </th>
                                    <!-- <th scope="col">Tipo </th> -->
                                    <!-- <th scope="col">Detalle</th> -->
                                </tr>
                            </thead>
                            <tbody class="f_blanco ">
                                <tr *ngFor="let producto of productos | filter : { 'name' : searchString, 'price' : searchString, 'detail' : searchString, 'category' : searchString  } ; index as i" (click)="editar(producto)" class="pointer" data-bs-toggle="offcanvas" data-bs-target="#modalProducto" aria-controls="modalProducto">
                                    <td>{{i + 1}}</td>
                                    <td>{{producto.name | titlecase}}</td>
                                    <td> $ {{producto.price | number: '1.0-0'}}</td>
                                    <!-- <td>{{producto.category}} </td> -->
                                    <!-- <td>{{producto.detail }}</td> -->
                                </tr>
                           
                            </tbody>
                        </table>                    
                    </div>

                    <div *ngIf="productos.length < 1"class="col-12 text-center">
                        <p style="padding-top:20px;" >Aún no haz creado ningún producto</p>
                        <!-- <button class="btn btn-outline-primary" (click)="nuevoProducto()">Crear un producto</button> -->
                    </div>
            </div>

            

        </div>
    </div>

    </div>
    <!-- Fin col-izquierda -->


    <div class="d-none d-lg-block col-lg-6 moradoDeg der">

        <div class="row pad20">
            <div class="col-12 text-center ">
                <h1 *ngIf="!existe" class="animate__animated animate__flipInY">Crear Producto</h1>
                <h1 *ngIf="existe" class="animate__animated animate__flipInY">Editar Producto</h1>
            </div>
        </div>

            <form (ngSubmit)="guardar( f )" #f="ngForm" class="row formulario">

                <div class="campo col-10 offset-1 animated slideInRight">
                    <label>Nombre del producto / servicio</label>
                    <span *ngIf="f.submitted && f.controls['name'].errors" class="text-warning animated fadeIn">Debes agregar un nombre al producto</span>
                    <input class="form-control formu" type="text" name="name" [(ngModel)]="product.name" required minlenth="6" placeholder="ej: Diseño de logo">
                </div>
    
                <div class="campo col-10 offset-1 mt-4 animated slideInRight">
                    <label>Precio</label>
                    <span *ngIf="f.submitted && f.controls['price'].errors" class="text-warning animated fadeIn">Debes agregar un precio</span>
                    <input class="form-control formu" type="number" name="price" [(ngModel)]="product.price" required minlenth="1" placeholder="ej: 2000">
                </div>
    
                <div class="campo col-10 offset-1 mt-4 animated slideInRight">
                    <label>Tipo</label>
                    <select class="form-control formu" name="category" [(ngModel)]="product.category">
                    <option>Producto</option>
                    <option>Servicio</option>
                  </select>
                </div>
    
                <div class="campo col-10 offset-1 mt-4 animated slideInRight">
                    <label>Observaciones</label>
                    <textarea class="form-control formu" name="detail" [(ngModel)]="product.detail" rows="3" placeholder="Info..."></textarea>
                </div>
    
    
                <div class="col-10 offset-1 animated slideInRight">
                    <button class="btn btn-lg btn-outline-light w-100 mt-4 " type="submit">
                    Guardar
                    </button>
                </div>
            </form>

            <div class="row">
                <div *ngIf="existe" class="col-10 offset-1 mt-2 pointer animated slideInRight text-end">
                    <p class="borrar" (click)="alertaBorrar(product)"> <i class="far fa-trash-alt"></i>Borrar Item</p>
             
    
                </div>
    
            </div>
    </div>
    <!-- Fin der -->
</div>


<div class="row botonPie d-block d-md-none" data-bs-toggle="offcanvas" data-bs-target="#modalProducto" aria-controls="modalProducto">
    <div class="col-12 text-center">
        <p>Crear Producto</p>
    </div>
</div>


<div class="d-block d-md-none offcanvas offcanvas-bottom moradoDeg " #demoModal data-bs-backdrop="false" tabindex="-1" id="modalProducto" aria-labelledby="modalProductoLabel">
    <div class="row modalProducto">
        <div class="col-12 text-end" style="padding:20px 40px 0 0 ">
            <i class="fas fa-times fa-2x text-light pointer" data-bs-dismiss="offcanvas" aria-label="Close"></i>
        </div>
    </div>

    <div class="bodyOffCanvas">
        <div class="row pad20">
            <div class="col-12 text-center text-light">
                <h1 *ngIf="!existe" class="animate__animated animate__flipInY">Crear Producto</h1>
                <h1 *ngIf="existe" class="animate__animated animate__flipInY">Editar Producto</h1>
            </div>
        </div>

        <form (ngSubmit)="guardar( f )" #f="ngForm" class="row formulario">

            <div class="campo col-10 offset-1 animated slideInRight">
                <label>Nombre del producto / servicio</label>
                <span *ngIf="f.submitted && f.controls['name'].errors" class="text-warning animated fadeIn">Debes agregar un nombre al producto</span>
                <input class="form-control formu" type="text" name="name" [(ngModel)]="product.name" required minlenth="6" placeholder="ej: Diseño de logo">
            </div>

            <div class="campo col-10 offset-1 mt-4 animated slideInRight">
                <label>Precio</label>
                <span *ngIf="f.submitted && f.controls['price'].errors" class="text-warning animated fadeIn">Debes agregar un precio</span>
                <input class="form-control formu" type="number" name="price" [(ngModel)]="product.price" required minlenth="1" placeholder="ej: 2000">
            </div>

            <div class="campo col-10 offset-1 mt-4 animated slideInRight">
                <label>Tipo</label>
                <select class="form-control formu" name="category" [(ngModel)]="product.category">
                <option>Producto</option>
                <option>Servicio</option>
              </select>
            </div>

            <div class="campo col-10 offset-1 mt-4 animated slideInRight">
                <label>Observaciones</label>
                <textarea class="form-control formu" name="detail" [(ngModel)]="product.detail" rows="3" placeholder="Info..."></textarea>
            </div>


            <div class="col-10 offset-1 animated slideInRight">
                <button class="btn btn-lg btn-outline-light w-100 mt-4 " type="submit">
                Guardar
                </button>
            </div>
        </form>

        <div class="row">
            <div *ngIf="existe" class="col-10 offset-1 mt-2 pointer animated slideInRight text-end">
                <p class="borrar" (click)="alertaBorrar(product)"> <i class="far fa-trash-alt"></i>Borrar Item</p>
            </div>
       </div>     

    </div>

</div>



<!-- <div class="row pie" style="margin:0">
    <div class="col-12" style="padding:0">
        <button class="button btn-lg btn-block btn-primary d-block d-lg-none" (click)="nuevoProducto()"> <i class="fas fa-plus"></i> Crear Producto</button>
    </div>
</div> -->



