<div class="row contenedor celeste r0">
    <div class="col-12 col-lg-6 izq ">

    <div class="row titulo text-center pad20 animate__animated animate__fadeIn">
        <div class="col-12">
            <h1 >Clientes </h1>
            <p>Creo o edita los perfiles de tus clientes</p>
        </div>
    </div>

    <div class="row">
        <div class="col-10 offset-1 bg-light cajaProductos animate__animated animate__slideInUp">

            
            <div *ngIf="buyers.length > 0" class="row pad20 ">
                    <div class="d-none d-md-block col-12 text-end">
                        <p class="nuevo pointer hvr-grow" data-bs-toggle="offcanvas" data-bs-target="#modalProducto" aria-controls="modalProducto" (click)="nuevoCliente()"><i class="fa-solid fa-plus" ></i> Crear nuevo cliente</p>
                    </div>
                    <div class="col-2 col-lg-1 offset-1 text-center hvr-icon-rotate text-end">
                        <i class="fas fa-search mt-1 icono2 hvr-icon" style="font-size:1.5em"></i>
                    </div>
                    <div class="col-9">
                        <input class="form-control" type="text" class="form-control " name="searchString " placeholder="Buscador... " [(ngModel)]="searchString " />
                    </div>
            </div>
          
            <div class="row">
                    <div *ngIf="buyers.length > 0"class="col-12 tabla">
                        <table class="table table-hover">
                            <thead class="">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Correo </th>
                                    <!-- <th scope="col">Teléfono </th> -->
                                </tr>
                            </thead>
                            <tbody class="f_blanco ">
                                <tr *ngFor="let buyer of buyers | filter : { 'first_name' : searchString, 'last_name' : searchString, 'email' : searchString, 'phone' : searchString  } ; index as i" (click)="verCliente(buyer)" class="pointer">
                                    <td>{{i + 1}}</td>
                                    <td>{{buyer.first_name | titlecase}} {{buyer.last_name | titlecase}}</td>
                                    <td>{{buyer.email}}</td>
                                    <!-- <td>{{buyer.phone}} </td> -->
                                </tr>
                           
                            </tbody>
                        </table>                    
                    </div>

                    <div *ngIf="buyers.length < 1"class="col-12 text-center">
                        <p style="padding-top:20px;">Aún no haz creado ningún cliente.</p>
                      <!-- <button class="btn btn-outline-primary" (click)="nuevoCliente()">Crear un cliente</button> -->
                    </div>
            </div>

            

        </div>
    </div>


    </div>
<!-- Fin columna izq -->

<div class="d-none d-lg-block col-lg-6 rosadoDeg der">

    <div class="row pad20">
        <div class="col-12 text-center ">
            <h1 *ngIf="!existe" class="animate__animated animate__flipInY">Crear Cliente</h1>
            <h1 *ngIf="existe" class="animate__animated animate__flipInY">{{buyer.name}}</h1>
        </div>
    </div>

        <form (ngSubmit)="guardar( f )" #f="ngForm" class="row formulario pad20">
            <div class="campo col-12 animated slideInRight">
                <label>Nombre</label>
                <span *ngIf="f.submitted && f.controls['first_name'].errors" class="text-warning animated fadeIn">Debes agregar un nombre</span>
                <input class="form-control formu" type="text" name="first_name" [(ngModel)]="buyer.first_name" required minlenth="2" placeholder="ej: Elvis">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Apellido</label>
                <span *ngIf="f.submitted && f.controls['last_name'].errors" class="text-warning animated fadeIn">Debes agregar un Apellido</span>
                <input class="form-control formu" type="text" name="last_name" [(ngModel)]="buyer.last_name" required minlenth="2" placeholder="ej: Presley">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Correo</label>
                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-warning animated fadeIn">Debes agregar un correo</span>
                <input class="form-control formu" type="email" name="email" [(ngModel)]="buyer.email" required minlenth="2" placeholder="ej: reydelrock@gmail.com">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Teléfono</label>
                <input class="form-control formu" type="text" name="phone" [(ngModel)]="buyer.phone" placeholder="ej: +569 98765432">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Observaciones</label>
                <textarea class="form-control formu" name="detail" [(ngModel)]="buyer.detail" rows="3" placeholder="Ej: Rey del rock, cobrarle a fin de mes"></textarea>
            </div>


            <div class="col-12 animated slideInRight">
                <button class="btn btn-lg btn-outline-light w-100 mt-4 " type="submit">
                    Guardar
                </button>
            </div>
        </form>
        <div class="row">
            <div *ngIf="existe" class="col-10 offset-1 mt-2 pointer animated slideInRight text-end">
                <p class="borrar" (click)="alertaBorrar(buyer)"> <i class="far fa-trash-alt"></i>Borrar Cliente</p>
            </div>
        </div>

           
</div>
<!-- fin der -->

</div>

<div class="row botonPie d-block d-md-none" data-bs-toggle="offcanvas" data-bs-target="#modalProducto" aria-controls="modalProducto">
    <div class="col-12 text-center">
        <p>Crear Cliente</p>
    </div>
</div>


<div class="d-block d-md-none offcanvas offcanvas-bottom rosadoDeg pad20" #demoModal data-bs-backdrop="false" tabindex="-1" id="modalProducto" aria-labelledby="modalProductoLabel">
    <div class="row modalProducto">
        <div class="col-12 text-end" style="padding:20px 40px 0 0 ">
            <i class="fas fa-times fa-2x text-light pointer" data-bs-dismiss="offcanvas" aria-label="Close"></i>
        </div>
    </div>

    <div class="bodyOffCanvas">
        <div class="row pad20">
            <div class="col-12 text-center text-light">
                <h1 *ngIf="!existe" class="animate__animated animate__flipInY">Crear cliente</h1>
                <h1 *ngIf="existe" class="animate__animated animate__flipInY">Editar cliente</h1>
            </div>
        </div>

        <form (ngSubmit)="guardar( f )" #f="ngForm" class="row formulario">
            <div class="campo col-12 animated slideInRight">
                <label>Nombre</label>
                <span *ngIf="f.submitted && f.controls['first_name'].errors" class="text-warning animated fadeIn">Debes agregar un nombre</span>
                <input class="form-control formu" type="text" name="first_name" [(ngModel)]="buyer.first_name" required minlenth="2" placeholder="ej: Elvis">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Apellido</label>
                <span *ngIf="f.submitted && f.controls['last_name'].errors" class="text-warning animated fadeIn">Debes agregar un Apellido</span>
                <input class="form-control formu" type="text" name="last_name" [(ngModel)]="buyer.last_name" required minlenth="2" placeholder="ej: Presley">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Correo</label>
                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-warning animated fadeIn">Debes agregar un correo</span>
                <input class="form-control formu" type="email" name="email" [(ngModel)]="buyer.email" required minlenth="2" placeholder="ej: reydelrock@gmail.com">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Teléfono</label>
                <input class="form-control formu" type="text" name="phone" [(ngModel)]="buyer.phone" placeholder="ej: +569 98765432">
            </div>

            <div class="campo col-12 animated slideInRight">
                <label>Observaciones</label>
                <textarea class="form-control formu" name="detail" [(ngModel)]="buyer.detail" rows="3" placeholder="Ej: Rey del rock, cobrarle a fin de mes"></textarea>
            </div>


            <div class="col-12 animated slideInRight">
                <button class="btn btn-lg btn-outline-light w-100 mt-4 " type="submit">
                    Guardar
                </button>
            </div>
        </form>
        
        <div class="row">
            <div *ngIf="existe" class="col-10 offset-1 mt-2 pointer animated slideInRight text-end">
                <p class="borrar" (click)="alertaBorrar(buyer)"> <i class="far fa-trash-alt"></i>Borrar Cliente</p>
            </div>
        </div>

       

    </div>

</div>



