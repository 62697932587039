<div *ngIf="loading" class="row loading animate__animated animate__fadeIn">
    <div class="col-12 text-center ">
        <h1><i class="fa-solid fa-spinner fa-spin"></i></h1>
        <p class="animate__animated animate__pulse animate__infinite">loading</p>
    </div>
</div>

<div *ngIf="!loading" class="todo">

    <div [ngClass]="payment.status == 2 ? 'pagado' : 'noPagado' " class="row animate__animated animate__fadeIn r0">
        
        <div *ngIf="payment.status == 2" class="col-12 col-md-8 offset-md-2 pagoOk">
            
            <div class="row titulo text-center text-light pad20">
                <div class="col-12">
                    <h1 ><i class="fa-solid fa-check fa-3x"></i></h1>
                    <h2>¡Muchas gracias, Pago realizado con éxito!</h2>
                    <p>Billing id: {{payment.billing_id}} | Payment id: {{payment.id}}</p>
                    <hr>
                </div>
            </div>
            <div class="row infopago text-light">
                <div class="col-12 col-md-4 text-center">
                    <p>Fecha: {{payment.reg_date | date: 'dd-MM-YYYY'}} a las {{payment.reg_date | date: 'HH:mm'}}</p>
                </div>
                <div class="col-12 col-md-4 text-center">
                    <p>Authorization Code: {{payment.auth_code}}</p>
                </div>
                <div class="col-12 col-md-4 text-center">
                    <p *ngIf="payment.payment_type == 'VD' ">Pago: Debito</p>
                    <p *ngIf="payment.payment_type == 'VN' ">Pago: Crédito</p>
                    <p *ngIf="payment.payment_type == 'VC' ">Pago: En cuotas</p>
                    <p *ngIf="payment.payment_type == 'SI' ">Pago: 3 Cuotas sin interés</p>
                    <p *ngIf="payment.payment_type == 'S2' ">Pago: 3 Cuotas sin interés</p>
                    <p *ngIf="payment.payment_type == 'NC' ">Pago: N Cuotas sin interés</p>
                    <p *ngIf="payment.payment_type == 'VP' ">Pago: Venta Prepago</p>
                </div>
            </div>


            <div class="row detalle">
                <div class="col-10 offset-1">

                    <div *ngFor="let o of orders" class="row producto">
                        <div class="col-6">
                            <p>{{o.product}}</p>
                        </div>
                        <div class="col-6 text-end">
                            <p>{{o.quantity}} x ${{o.price | number}}</p>
                        </div>
                    </div>

                    <div class="row total">
                        <div class="col-12 text-end text-light">
                            <h1>Total: $ {{payment.amount | number}}</h1>
                        </div>
                    </div>

                </div>
            </div>


            <div class="row mt-2">
                <div class="col-12 text-center">
                    <button class="btn btn-light" (click)="descargarInvoice()">Descargar comprobante</button>
                </div>
            </div>
          
        </div>

        <div *ngIf="payment.status != 2" class="col-12 col-md-8 offset-md-2 error">
            <div class="row titulo text-center text-light pad20">
                <div class="col-12">
                    <h1 ><i class="fa-regular fa-face-sad-tear fa-2x"></i></h1>
                    <h2>Lo sentimos, hubo un problema con el pago</h2>
                    <p>Billing id: {{payment.billing_id}} | Payment id: {{payment.id}}</p>
                    <hr>
                </div>
            </div>
        </div>
    </div>
    
</div>
<!-- fin loading -->


<!-- ME QUEDA CAMBIAR EL ESTADO DEL Billing -->