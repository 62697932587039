import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


import { LoginComponent } from './components/logueo/login/login.component';
import { RegistroComponent } from './components/logueo/registro/registro.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ResetpassComponent } from './components/logueo/resetpass/resetpass.component';
import { ProductsComponent } from './components/products/products.component';
import { ClientesComponent } from './components/clientes/clientes/clientes.component';
import { ClienteComponent } from './components/clientes/cliente/cliente.component';
import { VentaComponent } from './components/venta/venta.component';
import { BillingsComponent } from './components/billings/billings.component';
import { OrdersComponent } from './components/orders/orders.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { OkComponent } from './components/ok/ok.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'registro', component: RegistroComponent},
  { path: 'resetpass', component: ResetpassComponent},
  { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'productos', component: ProductsComponent, canActivate: [AuthGuard]},
  { path: 'clientes', component: ClientesComponent, canActivate: [AuthGuard]},
  { path: 'cliente/:id', component: ClienteComponent, canActivate: [AuthGuard]},
  { path: 'venta/:buyer', component: VentaComponent, canActivate: [AuthGuard]},
  { path: 'billings', component: BillingsComponent, canActivate: [AuthGuard]},
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]},
  { path: 'invoice/:id', component: InvoiceComponent},
  { path: 'ok/:id', component: OkComponent},
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: '**', pathMatch: 'full', redirectTo: 'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
