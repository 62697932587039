<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading">

<div class="row encabezado animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-6">
        <h1>Bienvenido: <br> {{usuario.firstname}} {{usuario.lastname}}</h1>
    </div>
</div>

<div class="row r0 animate__animated animate__fadeIn d-none d-md-block">
    <div class="col-12">
        <button class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#modalClient" aria-controls="modalClient" >Vender</button>
    </div>
</div>


<!-- Vista Cel -->

<div class="row botonPie d-block d-md-none pointer r0" data-bs-toggle="offcanvas" data-bs-target="#modalClient" aria-controls="modalClient">
    <div class="col-12 text-center">
        <p>Vender</p>
    </div>
</div>


<div class="offcanvas offcanvas-bottom moradoDeg pad20 " #demoModal data-bs-backdrop="false" tabindex="-1" id="modalClient" aria-labelledby="modalClientLabel" data-bs-backdrop="static">
    <div class="row modalClient v" style="color:white ">
        <div class="col-8" style="padding:10px 0 0 10px "> 
            <h1>Selecciona<br>un cliente</h1>
        </div>
        <div class="col-4 text-end" style="padding:20px 40px 0 0 "> 
            <i class="fas fa-times fa-2x text-light pointer" data-bs-dismiss="offcanvas" aria-label="Close"></i>
        </div>
    </div>

    <div class="bodyOffCanvas" style="color:white">

        <div *ngIf="!loadingClientes" class="row buscador animated fadeIn">
            <div class="col-2 text-center">
                <i class="fas fa-search fa-2x mt-1 text-light"></i>
            </div>
            <div class="col-9 ml-1">
                <input class="form-control" type="text" class="form-control " name="searchString " placeholder="Buscador... " [(ngModel)]="searchString " />
            </div>
        </div>

        <div *ngIf="buyers.length > 0" class="table-responsive tabla animated fadeIn">
            <table class="table table-hover">
                <tbody>
                    <tr *ngFor="let buyer of buyers | filter : { 'first_name' : searchString, 'last_name' : searchString  } ; index as i"  data-bs-dismiss="offcanvas" (click)="selectBuyer(buyer)" class="pointer" >
                        <td>{{buyer.first_name | titlecase}} {{buyer.last_name | titlecase}}</td>
                    </tr>
               
                </tbody>
            </table>
        </div>
       
        <div *ngIf="buyers.length < 1" class="animate__animated animate__fadeIn row">
            <p>No haz creado clientes aun</p>
            <a class="text-light" routerLink="/clientes">ir a Clientes <i class="fa-solid fa-arrow-right"></i></a>
        </div>


        
    

       

    </div>

</div>



</div>


