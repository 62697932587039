

<div class="row r0">
    <div class="col-12 col-md-7 izq">

        <div class="row pad20 nombres animate__animated animate__fadeIn">
            <div class="col-12 col-md-8">
                <h1>Kanasta</h1>
                <h2>para {{buyer.first_name}} {{buyer.last_name}}</h2>
                <h5>Pincha los productos que quieres agregar a la kanasta</h5>
            </div>
            <div class="col-12 col-md-4">
                <button class="btn btn-outline-success" data-bs-toggle="offcanvas" href="#modalProductos" role="button" aria-controls="modalProductos" >Buscar Producto</button>
            </div>
        </div>

    <hr>

    <div *ngIf="!loading"class="row productos">
        <div *ngIf="productos.length > 0" class="col-10 offset-1">

            <div *ngFor="let p of productos" class="row producto shadow animate__animated animate__flipInX" (click)="seleccionarProducto(p)">
                <div class="col-9 col-md-8 info pointer">
                      <h4>{{p.name}}</h4>  
                      <h5>{{p.detail | slice:0:50}}</h5>
                      <p class="d-block d-md-none text-end">$ {{p.price | number}}</p>
                </div>
                <div class="col-md-2 d-none d-md-block precio">
                      <p class="text-end">$ {{p.price | number}}</p>
                </div>
                <div class="col-3 col-md-2 boton text-center hvr-icon-grow">
                        <i class="fas fa-add fa-2x hvr-icon"></i>
                        <p>Agregar</p>
                
                </div>
            </div>

        </div>
        <div *ngIf="productos.length < 1" class="col-10 offset-1">
            <p>No haz creado productos aún</p>
            <button class="btn btn-primary" routerLink="/productos">Crear productos</button>
        </div>
    </div>

    </div>


    <!-- fin izq -->
    <div class="col der d-none d-md-block">
       
        <div class="row">
            <div class="col-2 text-end" style="padding:0">
                <img src="/assets/images/kanasta.svg" width="55px" class="canasta">
            </div>
            <div class="col-6">
                  <h1 (click)="verLaCanasta()">Kanasta</h1>
            </div>
            <div class="col-4 text-end totalchico">
                  <h5>Total <span>$ {{total | number}}</span></h5>
            </div>

        </div>

        <div class="row canasta">
            <div class="col-12">

                <div *ngFor="let k of canasta" class="row inBasket">
                    <div class="col-5">
                        <h2>{{k.product}}</h2>
                        <h5>{{k.fechai | date: 'dd-MM-yyyy'}}</h5>
                    </div>
                    <div class="col-3 hvr-icon-grow">
                        <p><i class="fas fa-minus hvr-icon text-danger pointer" (click)="modifCantidad('restar', k)"></i> {{k.quantity}} <i class="fas fa-add hvr-icon text-success pointer"  (click)="modifCantidad('sumar', k)"></i></p>
                    </div>
                    <div class="col-3 ">
                        <p (click)="cambiarPrecio(k)" class="pointer hvr-grow-rotate">$ {{k.price | number}}</p>
                    </div>
                    <div class="col hvr-icon-grow">
                        <i class="fas fa-times text-danger hvr-icon pointer" (click)="borrarItem(k)"></i>
                    </div>
                </div>

            </div>
        </div> 

        <div class="row total">
            <div class="col-12 text-end w-100">
                <h2>$ {{total | number}}</h2>
            </div>
        </div>
        <div *ngIf="canasta.length > 0" class="row">
            <div class="col-12">
                <button class="btn btn-outline-primary w-100 " (click)="kobrar()">Kobrar</button>
            </div>
        </div>

        <!-- <div class="row mt-2">
            <div class="col-12 ">
                <p class="text-end" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdjuntar" aria-expanded="false" aria-controls="collapseAdjuntar">
                    <i class="fa-solid fa-paperclip"></i> Adjuntar un archivo.
                </p>


                  <div class="collapse" id="collapseAdjuntar" class="formAdjuntar">
                    <div class="card card-body">
                        
                        <input class="form-control" type="file" (change)="onChange($event)">
             
                        <div class="col-12 mt-2 text-left">
                          <label>Nombre del archivo</label>
                          <input class="form-control" type="text" [(ngModel)]="file.name" maxlength="250" placeholder="nombre del archivo">
                        </div>
                   
                        <div class="col-12 mt-2">
                            <button (click)="onUpload()" class="btn btn-success w-100"> Subir </button>
                        </div>                    </div>
                  </div>

                  
           
        </div>
    </div> -->



</div>




<div class="offcanvas offcanvas-start" tabindex="-1" id="modalProductos" aria-labelledby="modalProductosLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="modalProductosLabel">Productos</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

    <div class="offcanvas-body">

        <div class="row">
            <div class="col-2 col-md-1 text-right">
                <i class="fas fa-search fa-2x mt-1 text-primary"></i>
            </div>
            <div class="col-10">
                <input class="form-control" type="text" class="form-control " name="searchString " placeholder="Buscador... " [(ngModel)]="searchString " />
            </div>
        </div>

        <div class="row">
        <div class="col-12">
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of productos | filter : { 'name' : searchString, 'price' : searchString } index as i" (click)="seleccionarProducto(p)">
                    <th scope="row">{{ i + 1}}</th>
                    <td>{{p.name}}</td>
                    <td>$ {{p.price | number}}</td>
                  </tr>
               
                </tbody>
              </table>
        </div>
    </div>
    
        

    </div>
  </div>


  <div class="row botonPie d-block d-md-none" data-bs-toggle="offcanvas" data-bs-target="#kanastaModal" aria-controls="kanastaModal">
    <div class="col-12 text-center">
        <p>Kanasta <span *ngIf="canasta.length > 0"> | {{canasta.length}}</span></p>
    </div>
</div>



<div class="offcanvas offcanvas-bottom kanastaModal pointer" tabindex="-1" id="kanastaModal" aria-labelledby="kanastaModalLabel">
    <div class="row" style="padding-bottom:20px">
        <div class="col-2 text-end" style="padding:0">
            <img src="/assets/images/kanasta.svg" width="55px" class="canasta">
        </div>
        <div class="col-6">
              <h1 (click)="verLaCanasta()">Kanasta</h1>
        </div>
        <div class="col-4 text-end totalchico">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
    </div>

    <div class="row canasta">
        <div class="col-12">

            <div *ngFor="let k of canasta" class="row inBasket">
                <div class="col-12">
                    <h2>{{k.product}}</h2>
                    <h5>{{k.fechai | date: 'dd-MM-yyyy'}}</h5>
                </div>
                <div class="col-6 hvr-icon-grow text-end">
                    <p><i class="fas fa-minus hvr-icon text-danger pointer" (click)="modifCantidad('restar', k)"></i> {{k.quantity}} <i class="fas fa-add hvr-icon text-success pointer"  (click)="modifCantidad('sumar', k)"></i></p>
                </div>
                <div class="col-4 text-end">
                    <p class="pointer hvr-grow-rotate">$ {{k.price | number}}</p>
                </div>
                <div class="col hvr-icon-grow">
                    <i class="fas fa-times text-danger hvr-icon pointer" (click)="borrarItem(k)"></i>
                </div>
            </div>

        </div>
    </div> 

    <div class="row total">
        <div class="col-12 text-end w-100">
            <h2>$ {{total | number}}</h2>
        </div>
    </div>
    <div *ngIf="canasta.length > 0" class="row">
        <div class="col-12">
            <button class="btn btn-outline-primary w-100 " (click)="kobrar()">Kobrar</button>
        </div>
    </div>


  


</div>


<!-- <div *ngIf="modalPrecio"class="row fondoModif r0 animate__animated animate__fadeIn">
    <div clas="col-12 ">

        <div class="row precioModif blanco animate__animated animate__slideInDown pad20">
            <div class="col-12">

                <div class="row">
                    <div class="col-10">
                        <h1>Modificar precio</h1>
                    </div>
                    <div class="col-2">
                        <i class="fas fa-times fa-2x" (click)="guardarPrecio2()"></i>
                    </div>
                </div>

                <div class="row formu">    
                    <div class="col-12">
                        <label>Precio</label>
                        <input class="form-control" type="number" [(ngModel)]="item.price"  placeholder="$">
                    </div>

                    <div class="col-12">
                        <button class="btn btn-primary" (click)="guardarPrecio2()" >Cambiar</button>
                    </div>
        
                </div>

            </div>

           
           
        </div>

       
    </div>
</div> -->