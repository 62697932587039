
export class UsuarioModel {
     id: string;
     firstname: string;
     lastname: string;
     rut: string;
     phone: string;
     email: string;
     account_name: string;
     account_number: string;
     account_type: string;
     account_rut: string;
     bank: string;
     planID: number;
     status: number;
     created_at: string;
     reg_date: string;
     brand: string;
     commerceCode: string;
     password:string;
     
     constructor() {

        this.id               = '';
        this.firstname        = '';
        this.lastname         = '';
        this.rut              = '';
        this.phone            = '';
        this.email            = '';
        this.account_name     = '';
        this.account_number   = '';
        this.account_type     = '';
        this.account_rut      = '';
        this.bank             = '';
        this.planID           = 1;
        this.status           = 1;
        this.created_at       = this.modificarFecha( new Date() );
        this.reg_date         = '';
        this.brand            = '';
        this.commerceCode     = '';
        this.password         = '';

     }

     modificarFecha(fecha) {
          const ano = (fecha.getFullYear()).toString();
          let mes = (fecha.getMonth() + 1).toString();
          let dia = (fecha.getDate()).toString();

          if ( mes.length < 2 ) {
            mes = '0' + mes;
          }

          if ( dia.length < 2 ) {
           dia = '0' + dia;
         }

          const fechaModif = dia + '-' + mes + '-' + ano;
          return fechaModif;
        }


}
