import { Component, OnInit, ɵConsole } from '@angular/core';
import { ConexionesService } from 'src/app/services/conexiones.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { Router } from '@angular/router';
import { FechasService } from 'src/app/services/fechas.service';
// import { MultiDataSet, Color, Label } from 'ng2-charts';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loading                      = true;
  loadingClientes              = true;
  modalClientes                = false;

  buyers: any[]                = [];
  usuario: UsuarioModel = new UsuarioModel();
 

  

  constructor(public conex: ConexionesService,
              public router: Router,
              public fechas: FechasService) {

                // // Primer dia del mes
                // this.firstDay = this.fechas.modificarFecha(new Date(this.date.getFullYear(), this.date.getMonth(), 1));

                // // Primer día hace 6 meses
                // this.meses = this.fechas.modificarFecha(
                //   new Date(
                //     new Date().getFullYear(),
                //     new Date().getMonth() - 5, 1)
                // );

                // // Último día del mes actual
                // this.lastDay = this.fechas.modificarFecha(
                //       new Date(this.date.getFullYear(),
                //       this.date.getMonth() + 1, 0));

                this.traerUsuario();

              }


  ngOnInit() {
    this.conex.sonido('settings.mp3');
  }

  selectBuyer(value) {
    console.log(value);
    this.router.navigateByUrl(`/venta/${value.id}`);
  }





traerUsuario() {
  const email = JSON.parse(localStorage.getItem('kobraUser')).email;

  this.conex.traeDatos(`/seller/${email}`)
            .subscribe( resp => {
              this.usuario = resp['datos'][0];
              console.log('usuario', this.usuario);
              this.loading = false;
              this.traerClientes();
            }, (err) => {
              this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
            });
}


   // Traigo listado de clientes para ir directo a venderles
   traerClientes() {
    this.loadingClientes = true;
    this.conex.traeDatos(`/tabla/BUYERS/${this.usuario.id}`)
              .subscribe( resp => {
                this.buyers           = resp['datos'];
                this.loadingClientes  = false;
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }




  // Status de Billings
  // 1 = grabado/impago
  // 2 = pagado
  // 0 = borrado













  error(titulo, mensaje) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }



}
