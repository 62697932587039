import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConexionesService } from 'src/app/services/conexiones.service';
import { BuyerModel } from 'src/app/models/buyer.model';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  loading                 = true;
  existe                  = false;
  mantenedor              = false;
  usuario: UsuarioModel;
  buyer: BuyerModel       = new BuyerModel();
  buyers: any[]           = [];
  searchString: string;

  constructor(private conex: ConexionesService,
              private router: Router) {
              this.traerUsuario();
  }

  ngOnInit() {
  }

  guardar(form: NgForm) {
    if (form.invalid) { return; }
    this.buyer.seller_id = Number(this.usuario.id);
    console.log('buyer', this.buyer);

    this.conex.guardarDato(`/buyers/insert`, this.buyer)
              .subscribe(resp => {
                console.log(resp);
                this.traerClientes();
                this.exito();
                this.buyer = new BuyerModel();
                this.mantenedor = false;
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }

  traerClientes() {
    this.loading = true;
    this.conex.traeDatos(`/tabla/BUYERS/${this.usuario.id}`)
              .subscribe( resp => {
                this.buyers = resp['datos'];
                this.loading = false;
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }


  traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;

    this.conex.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                console.log('thisusuario', this.usuario);
                this.traerClientes();
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }

  selectCliente(b:BuyerModel){
    this.buyer = b;
    console.log('este', this.buyer);
    this.existe = true;
  }


  verCliente(buyer){
    this.router.navigateByUrl(`/cliente/${buyer.id}`);
  }


  nuevoCliente(){
    console.log('nuevo');
  }

  //////////////// Warnings

  error(titulo, mensaje) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }

  exito() {
    Swal.fire({
      title: 'Listo',
      text: 'Datos guardados con exito',
      icon: 'success'
    });
}



}
