<div *ngIf="loading" class="row animate__animated animate__fadeIn r0">
    <div class="col-12">
        <p>Loading pago</p>
    </div>
</div>

<div *ngIf="!loading" class="row celeste todo animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 caja">

        <div class="row header">
            <div class="col-12">
                <h1>Hola {{invoice.buyerName}} {{invoice.buyerLast}}</h1>
                <h2 *ngIf="invoice.brand == '' ">Este es el detalle enviado por {{invoice.firstname}} {{invoice.lastname}}</h2>
                <h2 *ngIf="invoice.brand != '' ">Este es el detalle enviado por {{invoice.brand}}</h2>
                <h2>Kobro id: {{id}} | Estado:
                    <span *ngIf="invoice.status == 0" class="text-info">Borrado</span>
                    <span *ngIf="invoice.status == 1" class="text-danger">Pendiente</span>
                    <span *ngIf="invoice.status == 2" class="text-success">Pagado</span>
                </h2>
            </div>  
        </div>

        <div class="row orders animate__animated animate__flipInY">
            <div class="col-12">

                <div *ngFor="let o of orders" class="row order">
                   <div class="col-12">
                    <h3 class="hvr-grow">{{o.product}}</h3>
                    </div>
                    <div class="col-12 text-end">
                    <p text-end> {{o.quantity}}  x <span>$ {{o.price | number}} </span></p>
                   </div>
                   <hr>
                </div>


            </div>
        </div>

        <div class="row total animate__animated animate__fadeIn">
            <div class="col-12 text-end">
                <h1>Total: ${{invoice.amount | number}}</h1>
            </div>
        </div>
       
        <div *ngIf="invoice.status != 2" class="row botonPagar animate__animated animate__flipInX" >
            <div class="col-12 text-center">
                <button class="btn btnMorado w-100 btn-lg hvr-pulse-grow" (click)="crearPayment()">Pagar</button>
                <img src="/assets/images/wp.svg" width="150px" class="logo" (click)="crearPayment()">
            </div>
        </div>
   
    </div>
</div>