<div class="row celeste header animate__animated animate__fadeIn fadeIn r0">
    <div class="col-12 col-md-6 offset-md-3 pad20">
        <h1>En Kanasta</h1>
        <p>Listado con tus productos o servicios en Kanasta pero aún no kobrados</p>
        <h2>$ {{total | number}}</h2>
    </div>
</div>




<app-loading *ngIf="loading"></app-loading>


<div *ngIf="!loading" class="row celeste orders r0">
    <div class="col-10 offset-1 col-md-8 offset-md-2">


        <div *ngFor="let o of orders" class="row order shadow pointer animate__animated animate__flipInX " (click)="irCliente(o)">
            <div class="col-0 col-md-1">
                <img src="/assets/images/kanasta.svg"  class="canasta">
            </div>
            <div class="col-6 hvr-forward">
                <h1>{{o.product}}</h1>
                <p>{{o.first_name}} {{o.last_name}}</p>
                <p>{{o.fechai | date: 'dd-MM-YYYY'}}</p>
            </div>
            <div class="col-6 col-md-5 text-end hvr-backward">
                <p>{{o.price}} x ${{o.quantity}}</p>
                <h3>$ {{o.total | number}}</h3>
            </div>
        </div>

    </div>
</div>