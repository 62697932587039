import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioModel } from '../models/usuario.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urlBD = 'https://kobra-c0a88.firebaseio.com';
  private url = 'https://identitytoolkit.googleapis.com/v1';
  private apikey = 'AIzaSyAckMaqbwnwXv45EpHFAb-VHxw9sQKVnzo';
  userToken: string;

  constructor( private http: HttpClient) {
    this.leerToken();
  }

logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('expira');
  localStorage.removeItem('kobraUser');
}

login( usuario: UsuarioModel) {

  const authData = {
    email: usuario.email,
    password: usuario.password,
    returnSecureToken: true
  };
  return this.http.post( `${this.url}/accounts:signInWithPassword?key=${this.apikey}`, authData )
                  .pipe(
                    map( resp => {
                      this.guardarToken( resp['idToken']);
                      return resp;
                    })
                  );
 }


// GRABO EN FIREBASE
nuevoUsuario( usuario: UsuarioModel ) {
  const authData = {
                    email: usuario.email,
                    password: usuario.password,
                    returnSecureToken: true
                  };
  return this.http.post( `${this.url}/accounts:signUp?key=${this.apikey}`, authData )
                  .pipe(
                    map( resp => {
                      this.guardarToken( resp['idToken']);
                      return resp;
                    })
                  );
}

// SERVICIO DE FIREBASE
resetPass(correo) {
  const payload = {
                  requestType: 'PASSWORD_RESET',
                  email: correo
                  };
  return this.http.post( `${this.url}/accounts:sendOobCode?key=${this.apikey}`, payload )
                  .pipe(
                    map( resp => {
                      return resp;
                    })
                  );
}


// ====================================//
// ====================================//
// =========== AUTENTICADO ============//
// ====================================//
// ====================================//

private guardarToken(idToken: string) {

  this.userToken = idToken;
  localStorage.setItem('token', idToken);

  const hoy = new Date();
  hoy.setSeconds(3600);
  localStorage.setItem('expira', hoy.getTime().toString());
}

leerToken() {
  if (localStorage.getItem('item')) {
    this.userToken = localStorage.getItem('item');
  } else {
    this.userToken = '';
  }
  return this.userToken;
}

estaAutenticado(): boolean {

  if ( this.userToken.length > 2 ) {
    return true;
  }

  const expira = Number(localStorage.getItem('expira'));

  const expiraDate = new Date();
  expiraDate.setTime(expira);

  if ( expiraDate > new Date() ) {
    console.log('está autenticado');
    return true;

  } else {
    console.log('no está autenticado');
    return false;

  }
}



// ================ GUARDANDO EN BD =============== //

}


