import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ConexionesService } from 'src/app/services/conexiones.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { NgForm } from '@angular/forms';
import { FechasService } from 'src/app/services/fechas.service';
import { PaymentModel } from 'src/app/models/payment.model';
import Swal from 'sweetalert2';
import { BillingModel } from 'src/app/models/billing.model';
import { Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.css']
})
export class BillingsComponent implements OnInit {
  @ViewChild('myOffcanvas') offcanvasElement: ElementRef;

  usuario: UsuarioModel;
  loading  = true;
  loading2 = true;
  estado: 'Todos';
  
  date                      = new Date();
  firstDay: string;
  lastDay: string;

  billingsAll: any[]        = [];
  billings: any[]           = [];
  orders: any[]             = [];
  payment                   = '';

  verDetalle                = false;
  billDetalle: BillingModel = new BillingModel();

  guarandoText = 'Guardando Datos';
  guardando = false;
  searchString: string;

  cifras = {
            porKobrar:0,
            porKobrarTotal:0,
            pagados:0,
            pagadosTotal:0,
            total:0,
            totaltotal:0
          }



  public myAngularxQrCode: string = "";
  public qrCodeDownloadLink: SafeUrl = "";

  constructor(private conex: ConexionesService,
              private fechas: FechasService,
              private router: Router) {
                    this.myAngularxQrCode = 'Your QR code data string';

                this.traerUsuario();
                // this.firstDay = '2020-01-01';
                this.firstDay = this.fechas.modificarFecha(new Date(this.date.getFullYear(), this.date.getMonth(), 1));
                this.lastDay = this.fechas.modificarFecha(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0));
              }

  ngOnInit() {
    this.closeOffcanvas();

  }


  ngOnDestroy(): void {
    this.closeOffcanvas();
  }

  closeOffcanvas(): void {
    if (this.offcanvasElement && this.offcanvasElement.nativeElement) {
      const closeEvent = new Event('click');
      const closeButton = this.offcanvasElement.nativeElement.querySelector('[data-bs-dismiss="offcanvas"]');

      if (closeButton) {
        closeButton.dispatchEvent(closeEvent);
      }
    }
  }



  onChangeURL(url: SafeUrl) {
    console.log('url', url)
    this.qrCodeDownloadLink = url;
  }

  traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;

    this.conex.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                this.traerBillings(this.firstDay, this.lastDay);
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
                this.loading = false;
              });
  }

  traerBillings(fechaIni, fechaFin) {
    this.loading = true;
    this.conex.traeDatos(`/billings/${this.usuario.id}/${fechaIni}/${fechaFin}`)
              .subscribe( resp => {
                this.billingsAll = resp['datos']
                this.billings    = resp['datos']
                this.calcularCifras();
                this.loading = false;
              }, (err) => {
                this.loading = false;
                this.error('Error', err.errors);
              });
  }

  calcularCifras(){
    this.cifras = {
      porKobrar:0,
      porKobrarTotal:0,
      pagados:0,
      pagadosTotal:0,
      total: 0,
      totaltotal:0
    }

    for (let k of this.billings){
      if (k.status == 1){
        this.cifras.total ++
        this.cifras.totaltotal += k.amount;
        this.cifras.porKobrar ++
        this.cifras.porKobrarTotal += k.amount;
      }
      
      if (k.status == 2){
        this.cifras.total ++
        this.cifras.totaltotal += k.amount;
        this.cifras.pagados ++
        this.cifras.pagadosTotal += k.amount;
      }
    }

    console.log('cifras', this.cifras);
  }

  traerOrders(billing) {
    this.conex.traeDatos(`/ordersDetalle/${billing}`)
              .subscribe( resp => {
                this.orders = resp['datos'];
              }, (err) => {
                console.log(err.error);
              });
  }

  traerPayment(billing) {
    if ( billing.payment_id < 1) { return; }

    this.conex.traeDatos(`/payments/${billing.id}`)
              .subscribe( resp => {

                console.log('payments', resp);

                if (resp['datos'].length > 1){
                  console.log('hay varios');
                  this.payment = resp['datos'].find( (p:any) => p.status == 2);
                } else {
                  this.payment = resp['datos'][0];
                  console.log('payment', this.payment);
                }
              }, (err) => {
                console.log(err.error);
              });
  }

  buscar(form: NgForm) {
    this.traerBillings(form.value.fechaIni, form.value.fechaFin);
  }


  iniciodelostiempos() {
    this.traerBillings('2020-01-01', this.fechas.modificarFecha(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)) );
  }


  filtrarBillings(value) {
    if (value === '0' || value === 'undefined') {
      this.billings = this.billingsAll;
    } else {
      this.billings = this.billingsAll.filter( b => b.status === Number(value));
    }

    this.calcularCifras();
  }



  detalle(bill) {
    this.traerOrders(bill.id);
    this.traerPayment(bill);

    // this.verDetalle = true;
    this.billDetalle = bill;
    this.myAngularxQrCode = `https://www.kobra.cl/#/invoice/${this.billDetalle.id}`;
    console.log('bill', this.billDetalle);
  }

  kobrar(bill) {
    this.conex.enviarCorreo(bill, this.orders).
    subscribe( resp => {
      console.log('correo', resp)
      this.conex.sonido('cascabel.mp3');
      Swal.fire({
        title: ' Enviada',
        icon: 'success'
      });
    });
  }

  guardarPayment(bill) {

    console.log('bill', bill);
    
    this.guardando = true;

    const payment       = new PaymentModel();
    payment.status      = 2;
    payment.seller_id   = Number(this.usuario.id);
    payment.billing_id  = bill.id;
    payment.amount      = bill.amount;
    payment.origen      = bill.forma_pago;

    console.log('payment', payment);
    this.conex.guardarDato(`/payments/insert`, payment)
              .subscribe( resp => {
                bill.payment_id = resp['datos'].insertId;
                this.pagarBill(bill);
              });
  }


  pagarBill(bill) {
    this.guarandoText = 'Actualizando Kobros';
    bill.pay_at = new Date();
    bill.status = 2;
    this.conex.guardarDato('/billings/update', bill)
              .subscribe( resp => {
                this.conex.sonido('cascabel.mp3');
                this.actualizarOrders(3);
              });

  }

  actualizarOrders(status) {
    for ( const order of this.orders ) {
      order.status = status;
      this.conex.guardarDato('/updateOrder', order)
                .subscribe( resp => {
                            this.guardando = false;
                            }, (err) => {
                              console.log(err.error);
                              this.error('Error al guardar la canasta', err.error);
                            });
    }
  }

  deshacerPago(bill, payment) {
    this.guardando = true;

    const modifpayment = payment;
    modifpayment.status = 0;
    modifpayment.seller_id = 0;
    modifpayment.billing_id = 0;

    this.conex.guardarDato(`/payments/update`, modifpayment)
              .subscribe( resp => {
                this.verDetalle = false;
                this.deshacerBill(bill);
              });
  }


  deshacerBill(bill) {
    this.guarandoText = 'Actualizando Kobros';
    bill.pay_at = '';
    bill.status = 1;
    bill.payment_id = 0;
    this.conex.guardarDato('/billings/update', bill)
              .subscribe( resp => {
                this.actualizarOrders(2);
              });
  }

  verLink(){
    console.log('bill', this.billDetalle);
    this.router.navigateByUrl(`/invoice/${this.billDetalle.id}`);
  }


  shareWhatsapp(){
    console.log('whatsapp', this.billDetalle);
    const url = 'whatsapp://send?text=';
    const message = 'Hola, te comparto el link para que puedas revisar tu cuenta en: '
    const dynamicUrl = `https://www.kobra.cl/%23/invoice/${this.billDetalle.id}`;
    const final = url + message + dynamicUrl

    console.log('mensaje final', final );
    window.open(final ,'_blank');
  }

  // WARNINGS //
  error(titulo, mensaje) {
    this.conex.sonido('Error.mp3');

    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }


  async alertaPago(bill) {
      this.conex.sonido('Klick.mp3');
      Swal.fire({
        title: 'Forma de pago',
        text: '¿A través de que forma de pago fue cancelado este Kobro?',
        input: 'select',
        inputOptions: {
              Efectivo: 'Efectivo',
              Transferencia: 'Transferencia',
              Cheque: 'Cheque',
            },
            inputPlaceholder: 'Selecciona un medio de pago',
        showCancelButton: true,

        inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value !== '' ) {
                bill.forma_pago = value;
                this.guardarPayment(bill);
              } else {
                resolve('Tienes que escoger un medio de pago :)');
              }
              if (value) {
                Swal.fire(`Kobro guardado`);
                this.verDetalle = false;
              }
            });
          }
        });
  }

  alertaDeshacer(bill, payment) {
    this.conex.sonido('Error.mp3');
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Vas a deshacer un pago realizado',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.deshacerPago(bill, payment);
      } else {
        return;
      }
    });
  }


}
