import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { ConexionesService } from 'src/app/services/conexiones.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

 

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  loading               = true;
  total                 = 0;

  usuario: UsuarioModel;
  orders: any[]         = [];
  searchString: string;


  constructor(private conex: ConexionesService,
              private router: Router) {

              }

  ngOnInit() {
    this.traerUsuario();
  }


  traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;

    this.conex.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                console.log('usuario', this.usuario);
                this.traerOrders(this.usuario.id);
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
                this.loading = false;
              });
  }

  traerOrders(id) {
    this.total = 0;
    this.conex.traeDatos(`/enKanasta/${id}`)
              .subscribe( resp => {
                this.orders = resp['datos'];
                console.log('this.orders', this.orders);
                for (let o of this.orders){
                  o.total = o.quantity * o.price;
                  this.total += o.total;
                }
                this.loading = false;
              }, (err) => {
                console.log(err.error);
                this.loading = false;
              });
  }

  irCliente(order){
    console.log(order);
    this.router.navigateByUrl('/venta/' + order.buyer_id);
  }



   //////// WARNINGS /////
   error(titulo, mensaje) {
    this.conex.sonido('Error.mp3');

    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }

}
