
export class PaymentModel {
     id: number;
     seller_id: number;
     billing_id: number;
     amount: number;
     origen: string;
     status: number;
     token: string;
     auth_code: string;
     card: number;
     payment_type: string;
     vci: string;
     quotes: number;
     reg_date: string;


     //status
     //0: borrado:
     //1: creado - pendiente
     //2: pagado con exito;
     
     constructor() {
          this.id             = 0;
          this.seller_id      = 0;
          this.billing_id     = 0;
          this.amount         = 0;
          this.origen         = '';
          this.status         = 0;
          this.token          = '';
          this.auth_code     = '';
          this.card           = 0;
          this.payment_type   = '';
          this.vci            = '';
          this.quotes         = 0;
          this.reg_date       = '';
          }

}

