<div class="row r0" >
    
    <div class="col-12 col-md-6 celeste izq ">

        <div class="row animate__animated animate__fadeIn">
            <div class="col-12 col-md-5">
                <h1>{{buyer.first_name}} {{buyer.last_name}}</h1>
                <p>{{buyer.phone}}</p>
                <p>{{buyer.email}}</p>
            </div>
            
        </div>


        <div class="row mb-2 opciones" >
            <div class="col text-center">
                <p class="pointer mt-2 hvr-grow text-success" (click)="vender()"> <i class="fa-solid fa-basket-shopping"></i>  Vender</p>
            </div>
            <div class="col">
                <p data-bs-toggle="offcanvas" data-bs-target="#editCliente" aria-controls="editCliente" class="pointer mt-2 hvr-grow text-primary"><i class="fa-solid fa-pen"></i>Editar</p>
            </div>
            <div class="col">
                <p class="pointer mt-2 hvr-grow" routerLink="/clientes"><i class="fa-solid fa-arrow-left-long"></i>Volver</p>
            </div>
            <div class="col-1 text-center">
                <p class="pointer mt-2 hvr-grow text-danger" (click)="alertaBorrar()"><i class="fa-solid fa-trash-can"></i></p>
            </div>
        </div>


        <div class="row filtros mb-2">
            <div class="col-12 col-md-5">
                <label>Fecha Inicial</label>
                <input type="date" class="form-control animate__animated animate__flipInX" placeholder="Inicio Periodo" [(ngModel)]="firstDay" name="fechaIni" required>
            </div>
            <div class="col-12 col-md-5">
                <label>Fecha Final</label>
                <input type="date" class="form-control animate__animated animate__flipInX" placeholder="Fin Periodo" [(ngModel)]="lastDay" name="fechaFin" required>           
            </div>
            <div class="col-12 col-md-2">
                
                <button class="btn btn-primary mt-4 animate__animated animate__flipInX w-100" (click)="traerBillings()">Buscar</button>
            </div>

            
         </div>

         <hr>

         <div class="row kobros">
            <div *ngIf="billings.length < 1 "class="col-12  mt-2">
                <p>No hay kobros para este filtro</p>

            
            </div>
        
            <div *ngIf="billings.length > 0 "class="col-12">
                
                <div class="row estados">
                    <span>Estado de Kobro</span>
                    <select class="form-control" name="estado" (change)="filtrar($event.target.value)">
                        <option value="9">Todos</option>
                        <option value="1">Pendientes</option>
                        <option value="2">Pagados</option>
                    </select>
                </div>

                <div class="row kobros pointer" >
                    <div class="col-12">
                        <p>Kobros {{billings.length | number}}</p>
                        
                        <div *ngFor="let b of billings; index as i" (click)="verKobro(b)" class="animate__animated animate__flipInX" data-bs-toggle="offcanvas" data-bs-target="#canvasBilling" aria-controls="canvasBilling">
                            <div [ngClass]="b.bill_status > 1 ? 'pagadoBorder' : 'pendienteBorder' " class="row kobro shadow">
                                <div class="col-10 ">

                                    <div class="row" style="padding:15px">
                                        <div class="col-1 col-md-2"><p>{{ i + 1}} </div>
                                        <div class="col-10 col-md-5 "><p>{{b.created_at | date: 'dd-MM-yyyy'}}</p></div>
                                        <div class="col-12 col-md-5 text-end precio" ><p>${{b.amount | number}}</p></div>
                                    </div>
                                    
                                </div>
                                <div [ngClass]="b.bill_status > 1 ? 'pagado' : 'pendiente' " class="col-2 colorIcono hvr-icon-grow-rotate">
                                    <p *ngIf="b.bill_status > 1"><i class="fa-solid fa-check hvr-icon"></i></p>
                                    <p *ngIf="b.bill_status < 2"><i class="fa-solid fa-triangle-exclamation hvr-icon"></i></p>
                                </div>
                            </div>    
                         </div>

                    </div>

                   
                </div>
            </div>
         </div>
    </div>
    <!-- Fin izquierda -->


    <div class="col-md-6 d-none d-md-block webb animate__animated animate__fadeIn" >

        <div *ngIf="billing.id > 0 && loading2" class="row text-center loading2 animate__animated animate__fadeIn">
            <div class="col-12">
                <h1><i class="fa-solid fa-fan fa-spin"></i> loading</h1>
            </div>
        </div>

        <div *ngIf="billing.id > 0 && !loading2 " [ngClass]="billing.bill_status > 1 ? 'pagado' : 'pendiente' " class="row animate__animated animate__fadeIn bill">
            <div class="col-12">
                
                <div class="row titulo">
                    <div class="col-5 offset-1">
                        <h1><i class="fa-solid fa-basket-shopping"></i> Kanasta</h1>
                    </div>
                    <div class="col-5">
                        <p><span>Id: {{billing.id}} </span> </p>
                        <p>Id: {{billing.created_at | date: 'dd-MM-yyyy'}}</p>
                    </div>
                </div>

                <hr>

                <div *ngFor="let o of orders" class="row orders">
                    <div class="col-5 offset-1">
                        <h3>{{o.product}}</h3>
                    </div>
                    <div class="col-5 text-end">
                        <p><span>{{o.quantity}}  x </span> ${{o.price | number}}</p>
                    </div>
                    <hr>
                </div>

                <div class="row">
                    <div class="col-10 offset-1 text-end">
                        <h2 style="font-weight: 700;">$ {{total | number}}</h2>
                    </div>
                </div>


                <div *ngIf="billing.bill_status < 2" class="row mt-2">
                    <div class="col-5 offset-1">
                        <button class="btn btn-outline-light w-100" (click)="kobrar()"><i class="fa-solid fa-arrow-rotate-right"></i> Volver a Kobrar</button>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-outline-light w-100" (click)="alertaPago()"> <i class="fa-solid fa-check"></i> Pagar</button>
                    </div>
                </div>
             
                <div *ngIf="billing.bill_status > 1" class="row mt-4">
                    <div class="col-10 offset-1 text-center pagos">
                      <p>Pagado el {{payment.reg_date | date: 'dd-MM-yyyy'}} a las {{payment.reg_date | date: 'HH:mm'}}</p>
                      <p>Medio de pago: {{payment.origen}}</p>
                    </div>
                   
                </div>

              
            </div>
        </div>

    </div>
    <!-- Fin derecha -->
</div>







<!-- ======================================================================-->
<!-- ======================================================================-->
<!-- ============================off canvas billing =======================-->
<!-- ======================================================================-->
<!-- ======================================================================-->
<!-- ======================================================================-->


<div class="offcanvas offcanvas-top webb d-block d-md-none" tabindex="-1" id="canvasBilling" data-bs-backdrop="false" aria-labelledby="canvasBillingLabel">
    <!-- <div class="col-md-6 d-none d-md-block  animate__animated animate__fadeIn" > -->

        <div *ngIf="billing.id > 0 && loading2" class="row text-center loading2 animate__animated animate__fadeIn">
            <div class="col-12 pad20">
                <h1><i class="fa-solid fa-fan fa-spin"></i> loading</h1>
            </div>
        </div>

        <div *ngIf="billing.id > 0 && !loading2 " [ngClass]="billing.bill_status > 1 ? 'pagado' : 'pendiente' " class="row animate__animated animate__fadeIn bill">
    
            
            <div class="col-12">
                <div class="row titulo">
                        <div class="col-12 text-end" style="padding-right:40px">
                            <button type="button" class="btn-close text-reset btn-close-white " data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                    <div class="col-5 offset-1">
                        <h1><i class="fa-solid fa-basket-shopping"></i> Kanasta</h1>
                    </div>
                    <div class="col-5">
                        <p><span>Id: {{billing.id}} </span> </p>
                        <p>Id: {{billing.created_at | date: 'dd-MM-yyyy'}}</p>
                    </div>
                </div>

                <hr>

                <div *ngFor="let o of orders" class="row orders">
                    <div class="col-5 offset-1">
                        <h3>{{o.product}}</h3>
                    </div>
                    <div class="col-5 text-end">
                        <p><span>{{o.quantity}}  x </span> ${{o.price | number}}</p>
                    </div>
                    <hr>
                </div>

                <div class="row">
                    <div class="col-10 offset-1 text-end">
                        <h2 style="font-weight: 700;">$ {{total | number}}</h2>
                    </div>
                </div>


                <div *ngIf="billing.bill_status < 2" class="row mt-2">
                    <div class="col-10 offset-1">
                        <button class="btn btn-outline-light w-100" (click)="kobrar()"><i class="fa-solid fa-arrow-rotate-right"></i> Volver a Kobrar</button>
                    </div>
                    <div class="col-10 offset-1 mt-2">
                        <button class="btn btn-outline-light w-100" (click)="alertaPago()"> <i class="fa-solid fa-check"></i> Pagar</button>
                    </div>
                </div>
             
                <div *ngIf="billing.bill_status > 1" class="row mt-4">
                    <div class="col-10 offset-1 text-center pagos">
                      <p>Pagado el {{payment.reg_date | date: 'dd-MM-yyyy'}} a las {{payment.reg_date | date: 'HH:mm'}}</p>
                      <p>Medio de pago: {{payment.origen}}</p>
                    </div>
                   
                </div>

              
            </div>
        </div>

    
</div>

<!-- ======================================================================-->
<!-- ======================================================================-->
<!-- ============================Editar CLIENTE=============================-->
<!-- ======================= ===============================================-->
<!-- ======================================================================-->
<!-- ======================================================================-->

<div class="offcanvas offcanvas-end editClient rosadoDeg " tabindex="-1" id="editCliente" aria-labelledby="editClienteLabel"  data-bs-backdrop="static">
    <div class="row f_pink_light">
        <div class="col-10 pt-2">
            <h1>Editar <br> Cliente</h1>
        </div>
        <div class="col-2 text-right mt-2 hvr-icon-rotate">
            <i class="fas fa-times fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close" ></i>
        </div>
    </div>

    <form (ngSubmit)="guardar( f )" #f="ngForm" class="row ">

        <div class="campo col-12 animated slideInRight">
            <span>Nombre</span>
            <span *ngIf="f.submitted && f.controls['first_name'].errors" class="text-warning animated fadeIn">Debes agregar un nombre</span>
            <input class="form-control" type="text" name="first_name" [(ngModel)]="buyer.first_name" required minlenth="2" placeholder="ej: Elvis">
        </div>

        <div class="campo col-12 animated slideInRight">
            <span>Apellido</span>
            <span *ngIf="f.submitted && f.controls['last_name'].errors" class="text-warning animated fadeIn">Debes agregar un Apellido</span>
            <input class="form-control" type="text" name="last_name" [(ngModel)]="buyer.last_name" required minlenth="2" placeholder="ej: Presley">
        </div>

        <div class="campo col-12 animated slideInRight">
            <span>Correo</span>
            <span *ngIf="f.submitted && f.controls['email'].errors" class="text-warning animated fadeIn">Debes agregar un correo</span>
            <input class="form-control" type="email" name="email" [(ngModel)]="buyer.email" required minlenth="2" placeholder="ej: reydelrock@gmail.com">
        </div>

        <div class="campo col-12 animated slideInRight">
            <span>Teléfono</span>
            <input class="form-control" type="text" name="phone" [(ngModel)]="buyer.phone" placeholder="ej: +569 98765432">
        </div>

        <div class="campo col-12 animated slideInRight">
            <span>Observaciones</span>
            <textarea class="form-control" name="detail" [(ngModel)]="buyer.detail" rows="3" placeholder="Ej: Rey del rock, cobrarle a fin de mes"></textarea>
        </div>


        <div class="col-12 animated slideInRight">
            <button class="btn  btn-outline-light w-100 mt-4 " type="submit">
                Guardar
      </button>
        </div>
    </form>

    <div class="row">
        <div class="col-12 animated slideInRight">
            <button class="btn btn-outline-dark btn-block mt-4 " (click)="alertaBorrar()"> <i class="far fa-trash-alt"></i> Borrar
              </button>
        </div>
    </div>
  </div>







