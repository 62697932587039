import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ConexionesService } from 'src/app/services/conexiones.service';
import { OrderModel } from 'src/app/models/order.model';
import { BillingModel } from 'src/app/models/billing.model';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { BuyerModel } from 'src/app/models/buyer.model';
import { FileModel } from 'src/app/models/file.model';


@Component({
  selector: 'app-venta',
  templateUrl: './venta.component.html',
  styleUrls: ['./venta.component.css']
})
export class VentaComponent implements OnInit {

  loading                = true;
 
  buyerId:any            = '';
  buyer: BuyerModel      = new BuyerModel();
  productosAll: any[]    = [];
  productos: any[]       = [];
  searchString           = '';


  hoy                    = new Date();
  verCanasta             = false;
  usuario: UsuarioModel;
  canasta: any[]          = [];
  total                   = 0;
  items                   = 0;
  guardandoText           = 'Guardando Datos';
  guardando               = false;

  modalPrecio             = false;
  item:any                = {};

  //subir archivo
  file: FileModel         = new FileModel();
  shortLink: string       = "";
  loading2: boolean       = false; // Flag variable
  file2: File             = null; // Variable to store file
  files: any[]            = [];

  constructor( private router: Router,
               private route: ActivatedRoute,
               private conex: ConexionesService) {


               this.buyerId = this.route.snapshot.paramMap.get('buyer');
               this.traerBuyer(this.buyerId);
               this.traerUsuario();

  }

  ngOnInit() {
  }

  verLaCanasta(){
    console.log('canmasta', this.canasta)
    console.log('seller', this.usuario)
  }

verFecha(){
  console.log(this.hoy.toLocaleDateString());
}



detalle() {
  console.log('canasta', this.canasta);
}

 traerBuyer(buyerId) {
  this.conex.traeDatos(`/buyer/${buyerId}`)
      .subscribe( resp => {
        this.buyer = resp['datos'][0];
        console.log('buyer', this.buyer);
      }, (err) => {
        this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
      });
 }


  traerUsuario() {
    const email = JSON.parse(localStorage.getItem('kobraUser')).email;

    this.conex.traeDatos(`/seller/${email}`)
              .subscribe( resp => {
                this.usuario = resp['datos'][0];
                console.log('seller', this.usuario);
                this.traerProductos();
                this.traerOrders();
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }

  traerProductos() {
    this.conex.traeDatos(`/tabla/PRODUCTS/${this.usuario.id}`)
              .subscribe( resp => {
                console.log('prodcutos', resp);
                this.productosAll = resp['datos'];
                this.productos    = resp['datos'];
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }

  traerOrders() {
    this.conex.traeDatos(`/ordersCanasta/${this.buyerId}`)
              .subscribe( resp => {
                this.loading = false;
                const resultado = resp['datos'];
                console.log('resultados', resultado);

                if (resultado.length > 0) {
                  this.canasta = resultado;
                  console.log('canasta', this.canasta);
                  this.sumTotal();
                } else {
                }
              }, (err) => {
                this.error('Error de conexión', 'Revisa tu conexion a internet por favor');
              });
  }

  // ===================================== //
  // ===================================== //
  // ===================================== //
  // Movimientos de Kanasta ============= //
  // ===================================== //
  // ===================================== //
  // ===================================== //


  seleccionarProducto(producto) {
    this.conex.sonido('Klick.mp3');

    const item = new OrderModel();
    item.id = this.generarId();
    item.seller_id = producto.seller_id;
    item.buyer_id = this.buyerId;
    item.product = producto.name;
    item.product_id = producto.id;
    item.price = producto.price;
    item.status = 1;

    const repetido = this.canasta.find( i => i.product === producto.name && i.price === producto.price);
    if ( repetido ) {
        console.log('repetido');
        this.modifCantidad('sumar', repetido);
        return;
    }

    this.newOrder(item)
  
  }

  newOrder(item){
    const nuevos = [item];
    console.log('agreo item', item);
    this.conex.guardarDato('/nuevaOrder', nuevos)
    .subscribe( resp => {
      console.log('guardé la order', resp);
       const id = resp['datos'].insertId;
       item.id = id;
       this.canasta.push(item);
       this.sumTotal();
       this.alertAgrega(item);
    })

   
  }


  modifCantidad(operacion, item) {
    this.conex.sonido('Klick.mp3');
    console.log(operacion, item);
    if ( operacion === 'sumar') {
      item.quantity ++;
      this.updateOneOrder(item);
    } else {
      item.quantity --;
      if ( item.quantity === 0) {
        this.borrarItem(item);
      } else {
        this.updateOneOrder(item);
      }
    }
    this.sumTotal();
  }

  async cambiarPrecio(item) {
    const memoria          = item.price.toString();
    let precioValido:any   = 0;

    this.conex.sonido('Klick.mp3');
    let { value: precio } = await Swal.fire({
      title: 'Cambiar Precio',
      input: 'number',
      inputPlaceholder: '$' + item.price
      
    });
    precioValido = isNaN(precio as any);
    console.log('precio', precio);
    console.log('precioValido', precioValido);


    if (precio < 0 || precioValido || precio == null || precio ==''){
      this.error('Precio erroneo', 'Debes poner un valor mayor o igual a cero')
      item.price = Number(memoria);
      return
    }
    item.price = precio;
    this.updateOneOrder(item);
    this.sumTotal();
  }

  cambiarPrecio2(item){
    this.modalPrecio = true;
    this.item = item;
    console.log('item', item);
  }

  guardarPrecio2(n){
    console.log('n',n);
    console.log('item', this.item);

  }
  

  borrarItem(producto) {
    this.conex.sonido('Klick.mp3');
    this.canasta = this.canasta.filter( valor => valor.id !== producto.id);
    this.sumTotal();

    // Si la order ya existia en la bd, entonces la tengo que borrar allá
    if (producto.status !== 0) {
      this.guardando = true;
      this.guardandoText = 'Eliminando producto';

      producto.status = 0;
      console.log('borro', producto);
      this.guardandoText = 'Actualizando Ventas';
      this.updateOneOrder(producto);

    }
  }


  updateOneOrder(producto){
    this.conex.guardarDato('/updateOrder', producto)
              .subscribe( resp => {
                          console.log('update', producto);
                          this.guardando = false;
                          }, (err) => {
                            console.log(err.error);
                            this.error('Error al guardar la canasta', err.error);
                          });
  }

  vaciarCanasta() {
    for ( const item of this.canasta) {
      this.borrarItem(item);
    }
  }


sumTotal() {
  let suma = 0;
  this.items = this.canasta.length;
  if (this.canasta.length < 1) { this.total = 0; }
  for ( const item of this.canasta ) {
    const total = item.quantity * item.price;
    suma += total;
  }
  this.total = suma;
}




generarId() {
    let i = 1;
    for (const item of this. canasta ) {
        if (i <= item.id) {
          i = item.id + 1;
        }
    }
    return i;
}





kobrar() {
  this.conex.sonido('Klick.mp3');
  this.guardando = true;

  console.log('kobrar');

  const billing = new BillingModel();
  billing.seller_id = this.usuario.id;
  billing.buyer_id  = this.buyerId;
  billing.amount    = this.total;


  this.conex.guardarDato('/billings/insert', billing)
            .subscribe( resp => {
              const billingId = resp['datos'].insertId;
              billing.id = resp['datos'].insertId;
              for (const producto of this.canasta) {
                producto.billing_id = billingId;
              }
              this.kobrarOrders(billing);
            }, (err) => {
              console.log(err.error);
            });
}

kobrarOrders(billing){
  const body = {
    buyer_id: this.buyerId,
    billing_id: billing.id
  }
  this.conex.guardarDato('/kobrarOrders', body)
      .subscribe( resp => { 
          this.sendMail(billing, this.canasta);
          console.log('kobré las orders', resp);
    })
}



sendMail(billing, orders) {
  
  

  const bill = {
    email: this.buyer.email,
    first_name: this.buyer.first_name,
    last_name: this.buyer.last_name,
    id: billing.id
  }

  this.conex.enviarCorreo(bill, orders).
  subscribe( resp => {
    console.log('correo', resp)
    this.guardando = false;
    this.conex.sonido('cascabel.mp3');
    this.router.navigateByUrl('/billings'); 
    Swal.fire({
      title: 'Cobranza Enviada',
      icon: 'success'
    });
  });
}

// =================================== //
// =================================== //
// =================================== //
// SUBIR ARCHIVO ====================> //
// =================================== //
// =================================== //
// =================================== //
getArchivos(){
  // this.conex.traeDatos('/archivos/'+ this.inmuebleId).subscribe( resp => {
  //   console.log('arhicos', resp['datos'])
  //   this.files = resp['datos'];
  // })
}

newFile(){
  this.file         = new FileModel();
}

// On file Select
onChange(event) {
this.file2 = event.target.files[0];
console.log('onchange', this.file2);
}

// OnClick of button Upload
onUpload() {

if (this.file2 == null){
  this.error('Ups','Selecciona un archivo para subir por favor');
  return;
}

if (this.file.name.length < 1){
  this.error('ups','Agrega un nombre al archivo por favor');
  return;
}

this.loading2 =  true;


console.log('file1', this.file);
console.log('file2', this.file2);

    // Create form data
    const formData = new FormData(); 
    // Store form name as "file" with file data
    formData.append("file", this.file2, this.file2.name);

    console.log('formData', formData);
    console.log('file', this.file);



     this.conex.guardarDato('/uploads', formData)
               .subscribe( (resp:any) => { 
                   console.log('subi archivo', resp);
                      if (resp.message == 'Upload was successful' ){
                        this.subirRuta(resp.data)
                        console.log('sib', resp.data);
                      } else {
                        this.error('error', 'ocurrio un error: ' + resp.message);
                        this.loading2 =  false;
                      }
                 })



}


subirRuta(url){
this.file.url            = url;
this.file.buyer_id       = this.buyerId

console.log('guardaré', this.file);

this.conex.guardarDato('/guardarArchivo', this.file)
          .subscribe(resp => { 
            console.log('guardé', resp);
            this.getArchivos();
            this.loading2     =  false;
          
            Swal.fire({
              title: 'Bien!',
              text: 'Archivo subido con exito',
              icon: 'success',
              confirmButtonText: 'Ok'
            });

        })

}

borrarFile(a){
this.conex.guardarDato('/post/borrarArchivo', a)
            .subscribe( (resp:any) => {
              this.getArchivos();
            } )
}


abrirFile(a){
console.log('file', a);
window.open(a.url, "_blank");
}


// WARNINGS




  error(titulo, mensaje) {
    this.conex.sonido('Error.mp3');

    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning'
    });
  }

  exito() {
    Swal.fire({
      title: 'Listo',
      text: 'Datos guardados con exito',
      icon: 'success'
    });
}



alertAgrega(producto) {
  console.log('pro', producto)
  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    // onOpen: (toast) => {
    //   toast.addEventListener('mouseenter', Swal.stopTimer);
    //   toast.addEventListener('mouseleave', Swal.resumeTimer);
    // }
  });

  Toast.fire({
    icon: 'success',
    title: 'Agregaste ' + producto.product
  });
}







}
