
export class BillingModel {
     id: number;
     seller_id: any;
     buyer_id: any;
     currency: string;
     amount: number;
     pay_at: string;
     status: number;
     payment_id: number;
     reg_date: string;
     created_at: string;

     constructor() {
          this.id           = 0;
          this.seller_id    = 0;
          this.buyer_id     = 0;
          this.currency     = 'CLP';
          this.amount       = 0;
          this.pay_at       = '';
          this.status       = 0;
          this.payment_id   = 0;
          this.reg_date     = '';
          this.created_at   = this.modificarFecha( new Date() );


     }

     modificarFecha(fecha) {
          const ano = (fecha.getFullYear()).toString();
          let mes = (fecha.getMonth() + 1).toString();
          let dia = (fecha.getDate()).toString();

          if ( mes.length < 2 ) {
            mes = '0' + mes;
          }

          if ( dia.length < 2 ) {
           dia = '0' + dia;
         }

          const fechaModif = ano + '-' + mes + '-' + dia;
          return fechaModif;
        }


}
