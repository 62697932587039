
<div *ngIf="router.url != '/login' ">
  <div *ngIf="!router.url.includes('/invoice')"  >
    <div *ngIf="!router.url.includes('/ok')">
    <app-navbar class="d-black d-md-none"> </app-navbar>
    <app-sidenav class="d-none d-md-block" [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-sidenav>
  </div>
  </div>
</div>

<div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded }">
  <router-outlet></router-outlet>
</div>
