<div class="row fondo ">

    <div class="col-10 offset-1 col-md-4 offset-md-4 col-lg-2 offset-lg-5 text-center">
        <img src="/assets/images/k_logo_blanco.svg" width="200px">
        <form (ngSubmit)="onSubmit( f )" #f="ngForm" class="animated fadeInRight">
            <!-- <h1>Crear una nueva cuenta</h1> -->

            <!-- <span class="text-danger">El correo es obligatorio</span> -->
            <div class="campo">
                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animated fadeIn">Debes agregar un correo</span>
                <input class="form-control " type="email" name="email" [(ngModel)]="usuario.email" required minlenth="3" email placeholder="Email">
            </div>

            <div class="campo" data-validate="Password is required">
                <span *ngIf="f.submitted && f.controls['pass'].errors" class="text-danger animated fadeIn">La contraseña debe de ser más de 6 letras</span>
                <input class="form-control" type="password" name="pass" [(ngModel)]="usuario.password" required minlenth="6" placeholder="Password">
            </div>

            <div class="campo">
                <span *ngIf="f.submitted && f.controls['nombre'].errors" class="text-danger animated fadeIn">Debes agregar tu nombre</span>
                <input class="form-control" type="text" name="nombre" [(ngModel)]="usuario.firstname" required minlenth="6" placeholder="Nombre">
            </div>

            <div class="campo">
                <span *ngIf="f.submitted && f.controls['apellido'].errors" class="text-danger animated fadeIn">Debes agregar tus apellidos</span>
                <input class="form-control" type="text" name="apellido" [(ngModel)]="usuario.lastname" required minlenth="6" placeholder="Apellidos">
            </div>

            <div class="">
                <button class="btn btn-outline-success btn-block mt-3" type="submit">
                Ingresar
                </button>
            </div>

            <div class="campo mb-4">
                <div class="form-check">
                    <input [(ngModel)]="recordarme" class="form-check-input" id="ckb1" type="checkbox" name="remember-me">
                    <label class="form-check-label text-light" for="defaultCheck1">
                        Recordarme
                    </label>
                </div>
            </div>

            <hr>

            <div>
                <a routerLink="/login" class="txt1 text-light">
                ¿Ya tienes cuenta?
              </a>
            </div>
        </form>





    </div>
</div>