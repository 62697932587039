<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
  <div class="header">
    <h1 class="title" style="color:white">
      Kobra
    </h1>
    <div class="toggle" (click)="handleSidebarToggle()">
      <img src="/assets/images/k_blanco.png" width="40px">
    </div>
  </div>

  <div class="content">
    <div class="link">
      <div class="content" routerLink="/dashboard" [routerLinkActive]="'active'"  >
        <div class="icon">
          <i class="fa-solid fa-house icono" ></i>
        </div>
        <div class="title">Home</div>
      </div>
    </div>
    

    <div class="link">
      <div class="content" [routerLink]="['/productos']" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-shirt icono"></i>
        </div>
        <div class="title">Productos</div>
      </div>
    </div>
  
    <div class="link">
      <div class="content" [routerLink]="['/clientes']" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-users icono"></i>
        </div>
        <div class="title">Clientes</div>
      </div>
    </div>

    <div class="link">
      <div class="content" [routerLink]="['/orders']" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fas fa-shopping-basket icono"></i>
        </div>
        <div class="title">En Kanasta</div>
      </div>
    </div>

    <div class="link">
      <div class="content" [routerLink]="['/billings']" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-sack-dollar icono"></i>
        </div>
        <div class="title">Kobranzas</div>
      </div>
    </div>
  
    <div class="link">
      <div class="content" [routerLink]="['/perfil']" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-user-pen"></i>
        </div>
        <div class="title">Perfil</div>
      </div>
    </div>
  

    <!-- <div class="link">
      <div class="content" [routerLink]="['/general/home']" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-inbox icono"></i>
        </div>
        <div class="title">Inbox</div>
      </div>
    </div> -->

    <div class="link">
      <div class="content" (click)="fullscreen()" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-expand icono"></i>
        </div>
        <div class="title">Pantalla completa</div>
      </div>
    </div>







  </div>




  
  <div class="footerNav fixed-bottom ">
    <div class="link">
      <div class="content" routerLink="https://www.gour-net.cl/clientes" [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa-solid fa-door-open"></i>
        </div>
        <div class="title">Salir</div>
      </div>
    </div>

    
  </div>
</div>