<nav class="navbar navbar-expand-lg barra">
    <div class="container-fluid hvr-icon-grow">
        <a class="navbar-brand" routerLink="/dashboard">        
        <img src="/assets/images/k_logo_blanco.svg" width="80px">
      </a>
      <i class="fa-solid fa-bars text-light pointer hvr-icon" data-bs-toggle="offcanvas" data-bs-target="#idMenu" aria-controls="idMenu"> </i>
    </div>
  </nav>


  <div class="offcanvas offcanvas-start moradoDeg" tabindex="-1" id="idMenu" aria-labelledby="offMenu">
    
    <div class="offcanvas-header row">
        <div class="col-10 ">      
            <h5 class="offcanvas-title" id="offMenu">Menu</h5>
        </div>
        <div class="col-2 hvr-icon-spin pointer">      
            <i class="fa-solid fa-xmark fa-2x  hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close" ></i>
        </div>
    </div>



    <div class="offcanvas-body ">
        <div class="row link pointer" >
            <div class="col-2 text-end hvr-icon-rotate">
                <i class="fa-solid fa-house icono hvr-icon" ></i>
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link" routerLink="/dashboard" data-bs-toggle="offcanvas">Home</a>
            </div>
         </div>

         <hr>
       
    
  
         <div class="row link pointer">
            <div class="col-2 text-end hvr-icon-rotate">
                <i class="fas fa-users icono hvr-icon" ></i>
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link" routerLink="/clientes" data-bs-toggle="offcanvas">Clientes</a>
            </div>
         </div>

         <hr>
        
         <div class="row link pointer">
            <div class="col-2 text-end hvr-icon-rotate">
                <i class="fas fa-socks icono hvr-icon" ></i>
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link" routerLink="/productos" data-bs-toggle="offcanvas">Productos</a>
            </div>
         </div>

         <hr>
     
     
         <div class="row link pointer">
            <div class="col-2 text-end hvr-icon-rotate">
                <i class="fas fa-shopping-basket icono hvr-icon" ></i>
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link" routerLink="/orders" data-bs-toggle="offcanvas">Kanasta</a>
            </div>
         </div>

         <hr>
     
         <div class="row link pointer">
            <div class="col-2 text-end hvr-icon-rotate">
                <i class="fa-solid fa-sack-dollar icono hvr-icon" ></i>
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link" routerLink="/billings" data-bs-toggle="offcanvas">Kobranzas</a>
            </div>
         </div>
         <hr>

         <div class="row link pointer">
            <div class="col-2 text-end hvr-icon-rotate">
                <!-- <i class="fas fa-user-astronaut icono hvr-icon" ></i> -->
                <i class="fa-solid fa-user-pen icono hvr-icon"></i>            
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link" routerLink="/perfil" data-bs-toggle="offcanvas">Perfil</a>
            </div>
         </div>

         <hr>
         
       
         <div class="row link pointer">
            <div class="col-2 text-end hvr-icon-rotate">
                <i class="fa-solid fa-door-open icono hvr-icon" ></i>
            </div>
            <div class="col-10 hvr-grow">
                <a class="nav-link"  (click)="salir()" data-bs-toggle="offcanvas">Salir</a>
            </div>
         </div>

         <hr>
     
  
    </div>

    </div>


<!-- <nav class="navbar navbar-expand-lg navbar-dark " style="background-color: #1C1C1C;">
    <img class="logo pointer" routerLink="/home" src="/assets/images/k_logo_blanco.svg">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
<span class="navbar-toggler-icon"></span>
</button>
    <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item router" routerLinkActive="active">
                <a class="nav-link" routerLink="/dashboard">Home</a>
            </li>
            <li class="nav-item router" routerLinkActive="active">
                <a class="nav-link" routerLink="/perfil"><i class="fas fa-user-astronaut"></i> Perfil</a>
            </li>
            <li class="nav-item router" routerLinkActive="active">
                <a class="nav-link" routerLink="/productos"><i class="fas fa-socks"></i> Productos</a>
            </li>
            <li class="nav-item router" routerLinkActive="active">
                <a class="nav-link" routerLink="/clientes"><i class="fas fa-users"></i> Clientes</a>
            </li>
            <li class="nav-item router" routerLinkActive="active">
                <a class="nav-link" routerLink="/orders"><i class="fas fa-shopping-basket"></i> Kanasta</a>
            </li>
            <li class="nav-item router" routerLinkActive="active">
                <a class="nav-link" routerLink="/billings"><i class="fas fa-comment-dollar"></i> Kobros</a>
            </li>
        </ul>
        <span class="navbar-text ">
        <button   class="btn btn-outline-danger" (click)="salir()">Salir</button>
    </span>
    </div>
</nav> -->