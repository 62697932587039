import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { QRCodeModule } from 'angularx-qrcode';

import { ConexionesService } from './services/conexiones.service';

import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { LoginComponent } from './components/logueo/login/login.component';
import { RegistroComponent } from './components/logueo/registro/registro.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ResetpassComponent } from './components/logueo/resetpass/resetpass.component';
import { ProductsComponent } from './components/products/products.component';
import { ClientesComponent } from './components/clientes/clientes/clientes.component';
import { ClienteComponent } from './components/clientes/cliente/cliente.component';
import { VentaComponent } from './components/venta/venta.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { BillingsComponent } from './components/billings/billings.component';
import { OrdersComponent } from './components/orders/orders.component';
import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { OkComponent } from './components/ok/ok.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    RegistroComponent,
    PerfilComponent,
    DashboardComponent,
    ResetpassComponent,
    ProductsComponent,
    FilterPipe,
    ClientesComponent,
    ClienteComponent,
    VentaComponent,
    LoadingComponent,
    BillingsComponent,
    OrdersComponent,
    SidenavComponent,
    InvoiceComponent,
    OkComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    QRCodeModule
  ],
  providers: [
    ConexionesService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
